<template lang="pug">
div
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
        .modal-content
          .modal-header
            h5.title {{ t("Alert Setting") }}
            button.close(
              type="button",
              data-dismiss="modal",
              aria-label="Close",
              @click="close"
            )
              span.pr-3(aria-hidden="true") &times;

          .modal-body.p-3
            label {{ t('Parameter') }}
            .row(:class="paramHide ? 'parameter-hide': ''")
              .col-6
                param-field(v-for="item in parameters.left" :key="item.id" :data="item" :modalLoading="loading")

              .col-6
                param-field(v-for="item in parameters.right" :key="item.id" :data="item" :modalLoading="loading")
            
            .d-flex.justify-content-center.mt-3
              button.btn(@click="paramHide = !paramHide" :class="paramHide ? 'btn-primary' : 'btn-light'") 
                | {{ paramHide ? t('Load more') : t('Hide') }}

          .modal-footer
            button.btn.btn-light(:disabled="loading" @click="close") {{ t('cancel') }}
            button.btn.btn-primary(:disabled="loading" @click="updateSetting") {{ loading ? t('Loading...') : t('update') }}

</template>

<script>

import ParamField from './ParamField.vue'
import {NS} from '../../../store'

import entity from '../../../entity'
import {each as _each} from '@/utils/utilities'

export default {
  props: ['open'],

  components: {
    ParamField
  },

  data() {

    return {
      loading: false,
      parameters: {
        left: [],
        right: []
      },

      paramHide: false
    }

  },

  methods: {

    updateSetting() {
      if (this.loading) return
      this.loading = true
      
      const callback = {
        success:({data, status}) => {
          if (status !== 200)
            throw new Error

          this.$toast.success(data.result.message || 'Successfully update setting')
          this.close()
          this.$emit('success')
        },

        error: (e) => {
          let err = e.response ? e.response.data : {}
          this.$toast.error(err.message || 'Failed to updated setting')
        },
        done: () => {this.loading = false}
      }
      this.$store.dispatch(`${NS.alert}/update_setting`, callback)
    },


    setParameters() {
      let parameters = [[],[]]
      let total = Object.keys(entity.AlertTitles).length
      this.paramHide = total > 5

      let split = Math.ceil(total/2)

      _each(entity.AlertTitles, key => {
        let [_, title, unit] = entity.AlertTitles[key]

          let param = {
              id: key,
              name: title,
              value: 0,
              unit: unit
          }
          parameters[0].length < split ? parameters[0].push(param) : parameters[1].push(param)
      })

      let [left, right] = parameters
      this.parameters = {...this.parameters, left, right}
    },


    close() {
      this.$emit('close')
    }

  },


  mounted() {
    this.setParameters() 
  }
  
}
</script>

<style scoped>
.parameter-hide {
  height: 100px;
  overflow: hidden;
}
</style>