<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog(role="document")
          .modal-content
            .modal-header
              .h5.font-weight-bold.card-title.px-3.matador-text-01 {{ title }} Device
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true", @click="showAdd = false") &times;
            .modal-body
              form.px-3.mt-3
                
                .form-group
                  label Device Type
                  .row
                    .col
                      select.form-control(style="background:none!important" v-model="device_type_id")
                        option(value="" selected disabled) Choose device type
                        option(v-for="item in deviceTypes" :key="item.id" :value="item.id") {{ item.name }}
                    

                .form-group
                  label UUID/IMEI
                  input.form-control(
                    type='text' 
                    placeholder='Insert UUID/IMEI (9167346195465986)', 
                    @change="changeImei"
                    @input="changeImei"
                    :value="inpImei"
                  )

                .form-group
                  label(for='username') Device Name
                  input#username.form-control(type='text' placeholder="Input Device Name", v-model="description")

                .form-group(v-if="showNetworkOption")
                  label Network Operator / Carrier
                  .row
                    .col
                      select.custom-select.form-control(
                        type='text'
                        v-model="network_provider"
                        :class="{ 'matador-placeholder': network_provider === ''}" 
                        style="color:black !important"
                      )
                        option(value="" selected disabled) Choose Operator
                        option(v-for="item in networks" :value="item.id") {{item.name}}
                    .col-auto.pl-0
                      matador-button(iconOnly, size="sm", @click="showOperatorModal=true")
                        .h3.m-0
                          plus(:size="64")
                
                .form-group(v-if="showNetworkOption")
                  label SIM Number
                  input.form-control(type='text' placeholder="Insert SIM Number",
                    @change="changePhone", @input="changePhone", :value="inpSim"
                  )

                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose()") Cancel
                  matador-button(:disabled="!isValid" @click="doSave") {{ buttonTitle }}
      
    network-operator-modal(
      v-if="showOperatorModal" 
      @close="showOperatorModal = false" 
      @appendNetwork="appendNetworkOperator"
    )

    device-type-modal(
      v-if="showDeviceTypeModal",
      @close="showDeviceTypeModal = false" 
      @appendType="appendDeviceType"
    )

    confirmation-modal(
      v-if="showCancelModal"
      @close="showCancelModal = false" 
      @confirm="doClose()" 
      title="Discard Changes"
      confirm-text="Yes, Discard"
    )
      p.m-0
        | Are you sure want to discard changes you made?
    
</template>

<script>
import config from '@/config';
import * as yup from 'yup';
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import NetworkOperatorModal from "./NetworkOperatorModal.vue"
import DeviceTypeModal from "./DeviceTypeModal.vue"

import entity from '../entity'
import Api from '@/utils/api'


export default {
  props: ['close', 'currentDevice', 'onSave', 'onDelete', 'visible'],  
  
  data(){
    return {
      selectDeviceTypeLoading: false,
      device_id: '',
      device_type_id: '',
      imei: '',
      sim_number: '',
      network_provider: '',
      description: '',
      networks: [],
      showOperatorModal: false,
      showDeleteModal: false,
      showCancelModal: false,
      showDeviceTypeModal: false,
      loading: false,
      deviceTypes: []
    }
  },
  watch: {
    currentDevice: function (newVal) {
      
      if(newVal) {
        this.device_id = newVal.device_id;
        this.imei = newVal.imei || "";
        this.sim_number = newVal.sim_number;
        this.network_provider = newVal.network_provider_id || "";
        this.description = newVal.description;
        this.device_type_id = newVal.device_type_id;
        return
      } 
      
      this.cleanForm()
    },
    
  },
  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    NetworkOperatorModal,
    ConfirmationModal,
    DeviceTypeModal,
  },
  
  computed: {

    showNetworkOption() {
      if (config.app.satelite_device_type_id !== this.device_type_id)
        return true

      this.network_provider = ''
      return false
    },

    auth() {
      return this.$store.state.auth;
    },
    
    isValid() {
      const shape = {
        imei: yup.string().required(),
        sim_number: yup.string().required(),
        network_provider: yup.string().required(),
        description: yup.string().required()
      }
      if (this.device_type_id === config.app.satelite_device_type_id) {
        delete shape.sim_number
      }

      let deviceSchema = yup.object().shape(shape);
      return deviceSchema.isValidSync(this.inputData);
    },

    inputData() {
      let obj = {
        device_type_id: this.device_type_id,
        device_id: this.device_id,
        imei: this.imei,
        is_vsat: this.sateliteType,
        sim_number: this.sim_number,
        network_provider: this.network_provider,
        description: this.description,
      };
      
      if (this.device_type_id === config.app.satelite_device_type_id) {
        obj.network_provider = 'satelite'
        obj.sim_number = this.imei
      }
        
      return obj;
    },
    title() {
      return !!this.device_id ? 'Edit' : 'Add'
    },
    buttonTitle() {
      return !!this.device_id ? 'Update' : 'Add'
    },
    inpSim(){
      return this.sim_number;
    },
    inpImei(){
      return this.imei;
    },
  },
  methods: {

    changeImei (event) {
      let val = event.target.value.trim()
      // It can only be positive integer or empty, and the rule can be modified according to the requirement.
      if (/^[0-9]\d*$|^$/.test(val)) {
          this.imei = val || ''
      } else {
          event.target.value = this.imei || ''
      }
    },
    changePhone (event) {
      let val = event.target.value.trim()
      // It can only be positive integer or empty, and the rule can be modified according to the requirement.
      if (/^[0-9]\d*$|^$/.test(val)) {
          this.sim_number = val || ''
      } else {
          event.target.value = this.sim_number || ''
      }
    },
    onClose() {
      if(this.isDirty) {
        this.showCancelModal = true;
      } else {
        this.doClose();
      }
    },

    appendDeviceType(type) {
      this.deviceTypes = [entity.DeviceTypeOptEntity(type), ...this.deviceTypes]
      this.device_type_id = type.device_type_id
    },

    appendNetworkOperator(network) {
      this.networks = [entity.NetworkOptEntity(network), this.networks]
      this.network_provider = network.network_provider_id
    },

    doClose() {
      this.showCancelModal = false;
      this.$emit('close');
    },
    
    delete() {
      this.showDeleteModal = true;
    },

    cleanForm() {
      this.device_id = null
      this.device_id = null
      this.device_type_id = ''
      this.sateliteType = false
      this.imei = null
      this.sim_number = null
      this.network_provider = ''
      this.description = null
    },

    doSave() {
      if (this.loading) return
      this.loading = true;

      const callback = !!this.currentDevice ? Api.device.update : Api.device.create

      callback(this.inputData)
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          
          this.cleanForm()
          this.$emit('onSave', data.result.data)
        })
        .catch(e => {
          let error = e.response ? e.response.data : e
          this.$toast.error(error.message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    // onSwitchSatelite() {
    //   this.sateliteType = !this.sateliteType
    // },

    getNetworkOperator() {
      Api.device.network_operator()
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          
          this.networks = data.result.data.map(item => entity.NetworkOptEntity(item))
        })
        .catch(e => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to load network provider')
        })
    },

    getDeviceTypes() {
      Api.device.collect_device_types()
      .then(({data, status}) => {
        if (status !== 200)
          throw new Error

        this.deviceTypes = data.result.data.map(item => entity.DeviceTypeOptEntity(item))
      })
      .catch(e => {
        let err = e.response ? e.response.data : e
        this.$toast.error(err.message || 'Fialed to load device types')
      })
    }
  },
  mounted(){
    this.getNetworkOperator();
    this.getDeviceTypes()
  }
}
</script>