'use strict'
import dayjs from 'dayjs'

const DetailMontoringEntity = (data) => {
    data = data || {}
    
    let battery = data.battery || {voltage: 0, level: 0}
    let position = data.coordinate || {lat: 0, lng: 0}
    let date = dayjs(data.updated_at)

    return {
        device: {
            name: data.device_name || '-',
            uuid: data.device_uuid || '-',
            battery: {
                volt: battery.voltage || 0,
                level: battery.level || 0
            },
        },
        
        position: {
            lat: parseFloat(position.lat),
            lng: parseFloat(position.lng)
        },

        vehicle: {
            name: data.vehicle_name || '-',
            vin: data.vehicle_vin || '-',
            image: data.vehicle_image || '',
        },

        timestamps: {
            timestamp: data.updated_at,
            updated_date: date.format('dddd, DD-MM-YYYY'),
            updated_time: date.format('h:mm:ss A')
        }

    }
    
}


const TimelineEntity = (data) => {
    data = data || {}

    let position = data.coordinate || {}
    let battery = data.battery || {}
    let stamp = dayjs(data.last_update_time)

    let latitude = position.lat || 0
    let longitude = position.lng || 0

    return {
        latitude,
        longitude,
        position: data.position || 'bottom',
        date: stamp.format('DD-MM-YYYY') || '',
        time: stamp.format('hh:mm A') || '',
        battery_volt: battery.voltage || 0,
        battery_level: battery.level || 0,
        isActive: data.active || false,
        isLast: data.is_last || false,
        timestamp: data.timestamp
    }
}


const DeviceTypeOptEntity = data => {
    return {
        id: data.device_type_id,
        name: data.name
    }
}

const NetworkOptEntity = data => {
    return {
        id: data.network_provider_id,
        name: data.name
    }
}

export default {
    DetailMontoringEntity,
    TimelineEntity,
    DeviceTypeOptEntity,
    NetworkOptEntity
}