const {each: _each} = require("@/utils/utilities")
const {default: Api}  = require("@/utils/api")
const { AlertTitles } = require('../entity')

const initState = {
    alerts: {},
    activeParams: {},
}

module.exports = {
    namespaced: true,
    state: {... initState},

    actions: {

        collect_alert({commit}, {success, error, done}) {
            Api.dashboard.collect_alerts()
                .then(response => {
                    if (response.status !== 200)
                        throw new Error
                    
                    commit('init_params', response.data.result.data)
                    success(response)
                })
                .catch(error)
                .finally(done)
        },

        update_setting({state}, {success, error, done}) {
            const parameters = state.activeParams
            Api.dashboard.update_alert_setting({parameters})
                .then(success)
                .catch(error)
                .finally(done)
        },
    }, 
    mutations: {
        
        init_params(state, props) {
            props = props || {}

            let alerts = props.alerts || {}
            let settings = props.settings || {}

            _each(alerts, key => {
                let alert = AlertTitles[key]
                if (alert) {
                    let [_, title, unit, icon] = alert
                    state.alerts[key] = {
                        id: key,
                        label: title,
                        icon: icon,
                        unit: unit,
                        count: Number(alerts[key]),
                        show: true
                    }
                }
            })
            
            _each(settings, key => {
                state.activeParams[key] = settings[key]
            })

            state.activeParams = {...state.activeParams}
            state.alerts = {...state.alerts}
        },

        update_alerts(state, props) {
            props = props || {}

            if (props.alerts && Object.keys(props.alerts).length) {
                let alerts = props.alerts

                _each(alerts, key => {
                    let alert = AlertTitles[key]
                    if (alert) {
                        let [_, title, unit, icon] = alert
                        state.alerts[key] = {
                            id: key,
                            label: title,
                            icon: icon,
                            unit: unit,
                            count: Number(alerts[key]),
                            show: true
                        }
                    }
                })

                state.alerts = {...state.alerts}
            }
            
        },


        toggle_param(state, props) {
            const data = props.data || {}
            
            if (!data.id) return

            let items = state.activeParams
            if (items[data.id]) {
                delete items[data.id]
            } else {
                items[data.id] = 0
            }

            state.activeParams = {...items}
        },

        set_param(state, props) {
            const data = props.data || {}
            state.activeParams[data.id] = Number(data.value)
            state.activeParams = {...state.activeParams}
        },
        
        
    }
}