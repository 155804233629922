const rand = (max, min) => Math.abs(Math.floor((Math.random() * max) - min))

const fakeData = (total) => {
  let data = []
  ;[...Array(total).keys()].forEach(i => data.push(rand(10, 50)))
  
  return data
}


// Core
// -------------
const colors = {
  line: {
    top: "#7998B9"
  },
  bar: {
    top: "#FDCA40",
    avg: "#7998B9"
  }
}

const lineChart = (data) => {
  data = data || {}
  return {
    label: data.label,
    data: data.data,
    borderColor: data.color,
    borderWidth: 3,
    pointRadius: 1,
    pointHoverRadius: 15,
    cubicInterpolationMode: 'monotone',
    backgroundColor(ctx) {
      const canvas = ctx.chart.ctx;
      const gradient = canvas.createLinearGradient(0,0,0,200);

      gradient.addColorStop(0, '#afbada');
      gradient.addColorStop(0.2, '#c9cedb');
      gradient.addColorStop(1, '#eff2f9');
      
      return gradient;
    },
    fill: true,
    stack: "speed-stack"
  }
}

const barChart = (data) => {
  data = data || {}

  return {
    label: data.label,
    data: data.data,
    backgroundColor: data.color,
    borderWidth: 2,
    borderRadius: 10,
    fill: true,
    stack: "speed-stack"
  }
}

const graphData = (labels, data, type) => {
  
  let datas = {}
  
  if (type == "line") {
    datas.top = lineChart({
      label: data.top.title,
      data: data.top.data,
      color: colors.line.top
    })
  } else {
    if (data.avg) {
      datas.avg = barChart({
        label: data.avg.title,
        data: data.avg.data,
        color: colors.bar.avg
      })
    }
    if (data.top) {
      datas.top = barChart({
        label: data.top.title,
        data: data.top.data,
        color: colors.bar.top
      })
    }
  }


  return {
    colors: colors[type],
    titles: {top: data.top.title, avg: data.avg.title },
    type,
    data: {
      labels,
      datasets: Object.values(datas)        
    },
    options: {
      responsive: true,
      legend: { display: true, position: 'bottom' },
      
      scales: { 
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'probability'
          }
        }]
      }
    }
  }
}

module.exports = {
  fakeData,
  graphData,
  colors
}