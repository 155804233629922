<template lang="pug">
div.d-flex.align-items-center(:style="{width: barWidth}")
  div.kpi-badge.mr-2
    span.kpi-badge-item.kpi-bar(:style="backgroundColor")
    span.kpi-badge-item.kpi-value.d-flex.align-items-center(:style="{color: textColor}") {{ percent }} %
</template>

<script>
export default {
  props: ['color', 'dataValue', 'dataBarWidth', 'dataTextColor'],
  
  computed: {
    percent() {
      return (Number(this.dataValue) || 0).toFixed(2)
    },

    backgroundColor() {
      return {
        background: this.color,
        width: `${this.percent}%`
      }
    },
    barWidth() {
      return this.dataBarWidth ? this.dataBarWidth : '150px'
    },
    textColor() {
      return this.dataTextColor ? this.dataTextColor : '#b4b4b4'
    }
  }
}
</script>

<style scoped>

.kpi-badge {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background: #F0F0F5;
  height: 15px;
  width: 100%;
}
.kpi-badge-item {
  z-index: 2;
  padding: 1px 10px;
  width: 100px;
  display: inline-block;
  font-size: 0.6rem;
}
.kpi-value {
  position: absolute;
  top: 0;
  left: 0;
}
.kpi-bar {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

</style>