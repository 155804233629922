<template lang="pug">
div(style="min-width: 500px")

  .row
    .col-md-7.col-12
      alert-statistic

    .col-md-5.col-12
      item-statistic

  .row
    .col-md-8
      graph-statistic(:statConfig="stats")
      on-board-info

    .col-md-4
      fleet-overview(:parameters="parameters")

</template>

<script>
import { NS } from './store'
import config from '@/config'
import AlertStatistic from "./sections/AlertStat.vue";
import ItemStatistic from "./sections/VehicleStat.vue";

import GraphStatistic from "./sections/GraphStat.vue";
import OnBoardInfo from "./sections/OnBoardInfo.vue";
import FleetOverview from "./sections/FleetOverview.vue";

import EV from '@/utils/emitter'


export default {
  components: {
    AlertStatistic,
    ItemStatistic,
    GraphStatistic,
    OnBoardInfo,
    FleetOverview,
  },

  data() {
    return {
      stats: {},
      parameters: {},

      background: null
    }
  },
  
  methods: {

    socket_event(payload) {
      
      switch(payload.event) {

        case 'alert':
          return this.$toast.error(payload.data.message, 3000)

        case 'update_dashboard':
          return this.$store.commit(`${NS.alert}/update_alerts`, payload.data)

      }
      
    },

    dashboardInterval() {
      if (this.background) clearInterval(this.background)

      let payload = {
        event: "check_update",
        data: {
          token: this.$cookies.get('token')
        }
      }

      // let delay = config.app.socket_delay.update_dashboard || 200000
      // this.background = setInterval(() => {
      //   if (!this.$store.state.socket) return
      //   this.$store.state.socket.emit('request', payload)
      // }, delay)
    }

  },
  

  mounted() {
    EV.on('socket', this.socket_event)

    this.dashboardInterval()
  },


  beforeDestroy() {
    if (!this.background) return
    clearInterval(this.background)
  }
  
};

</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.mt-badge {
  padding: 1px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;

  &.bad {
    color: #992024;
    background: #F0DDDE;
  }

  &.enough {
    color: #1F548B;
    background: #DDE5ED;
  }

  &.perfect {
    color: #0CCA4A;
    background: #DBF7E4;
  }

}
</style>