<template lang="pug">
div
  .card.p-4
    .card-header.p-0.pb-3
      .d-flex.justify-content-between.align-items-center
        h5.font-weight-bold.card-title
          | {{ t(`Vehicle Status`) }}

        .btn-header-wrapper.d-flex
          pop-info(:title="t(`Vehicle Status`)" data-id="vehicle-status") 
              | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          span 
            i.mt.mt-gear.cursor-pointer(@click="settingOpen = true")

    .card-body.p-0
      loader(v-if="loading" :loading="loading" style="height: 100px")
      div.pb-3.d-flex.justify-content-between(v-if="!loading")
        div.stat-item.mr-3( v-for="stat in statistics" :key="stat.id" )
          div.stat-count {{ stat.count }}
          div(:class="`stat-label ${stat.class}`")
            span.mr-1 <i class="mt mt-vehicle"></i>
            span {{ stat.label }}

      div.d-flex.justify-content-between.align-items-center(style="font-size: 0.7rem")
        div
          i.mt.mr-2(:class="isGood ? 'mt-thumbs-up good' : 'mt-thumbs-down bad'" style="font-size: 0.9rem")
          span {{operated.toFixed(2)}}% {{ t(`Operational Efficiency`) }}
        a.text-muted.cursor-pointer(@click="goToMonitor") {{ t(`See more`) }}

  threshold-setting-modal(
    v-if="settingOpen"
    :open="settingOpen"
    @close="settingOpen = false"
  )
</template>

<script>
import PopInfo from '../components/PopInfo'
import ThresholdSettingModal from '../components/modals/ThresholdSettingModal.vue'
import Api from '@/utils/api'

export default {
  components: {
    PopInfo,
    ThresholdSettingModal,
  },

  data() {
    return {
      loading: false,
      statistics: [],

      settingOpen: false,
      operated: 0,
      threshold: 0
    }
  },
  methods: {

    goToMonitor() {
      this.$router.push('/monitoring')
    },

    collectStatus() {
      if (this.loading) return
      this.loading = true

      Api.dashboard.collect_statuses()
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error

          let result = data.result.data.statuses || {}
          this.operated = data.result.data.operated || 0
          this.threshold = data.result.data.threshold || 0

          this.statistics = [
            {id: 'stat-on', label: 'Vehicle On', count: result.on || 0, class: "on"},
            {id: 'stat-idle', label: 'Vehicle Idle', count: result.idle || 0, class: "idle"},
            {id: 'stat-off', label: 'Vehicle Off', count: result.off || 0, class: "off"},
          ]
        })
        .catch(e => {
          const err = e.response ? e.response.data : e
          this.$store.commit('log', {mod: 'kpi:load_vehicle_status', e})
        })
        .finally(() => this.loading = false)
    }

  },

  computed: {
    isGood() {
      return this.operated >= this.threshold
    }
  },

  mounted() {
    this.collectStatus()
  }
}
</script>

<style scoped lang="scss">

.stat-item {
  
  .stat-count {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Open sans';
    text-align: center;
  }

  .stat-label {
    color: #8B90A0;
    font-size: 0.8rem;

    &.on { color: #0CCA4A; }
    &.off { color: #992024; }
  }

}
.mt.good { color:  #0CCA4A;}
.mt.bad { color:  #992024; }

</style>

