<template lang="pug">

.card.p-4
  .card-header.p-0.pb-3
    .d-flex.justify-content-between.align-items-center
      h5.font-weight-bold.card-title
        | {{ t(`On-Boarding Diagnosis`) }}

      .btn-header-wrapper
        pop-info(:title="t(`On-Boarding Diagnosis`)" data-id="onboard") 
          | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

  .card-body.p-0
    loader(:loading="loading")
      .d-flex.justify-content-center.flex-column.align-items-center(v-if="!vehicle.imei" style="min-height: 200px")
        p.text-muted {{ t('No data found') }}
    
    .row(v-if="vehicle.imei && !loading" style="width: 100%")
      .col-lg-6.col-md-12
        .card.info-wrapper
          .card-body.d-flex.justify-content-between
            div.d-flex
              .thumb.mr-4
                img(:src="vehicle.thumb || defaultThumb")
              .info
                div.d-flex.flex-column.mb-4
                  span.text-muted {{ t('Vehicle name') }}
                  span <strong>{{ vehicle.name }}</strong> 

                div.d-flex.flex-column
                  span.text-muted {{ t('Device name') }}
                  span <strong>{{ vehicle.device_name }}</strong> 

      .col-lg-6.col-md-12
        div.d-flex.flex-column.justify-content-center.align-items-center.flex-grow-1.mt-3
          div
            .code
              span(v-for="code in codes(dtc.code)") {{ code }}
          
          .flex-grow-1
            div.alert.alert-danger.mt-2.d-flex.align-items-center
              i.mt.mt-alert(style="font-size: 1.2rem")
              | {{ dtc.description }}

</template>

<script>

import { mapState } from 'vuex'
import PopInfo from '../components/PopInfo.vue'

import { NS } from '../store'

export default {
  components: {
    PopInfo
  },

  methods: {
    detail() {
      this.$store.dispatch(`${NS.on_board}/get_latest`, this)
    },
    codes(code) {
      return code ? code.split('') : []
    }
  },

  computed: {
    ...mapState(NS.on_board, ['vehicle', 'dtc', 'loading']),

    defaultThumb() {
      return require('@/assets/img/truck-dummy.png')
    },
  },

  mounted() {
    this.detail()
  }
}
</script>

<style scoped>

.info-wrapper {
  box-shadow: none;
  border: 1px solid #F0F0F5;
  margin: 0;
}

.thumb {
  width: 150px;
  height: 117px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.thumb img {
  width: 100%;
}

.code {
  background: #EDEDED;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.code span {
  padding: 10px;
  margin: 5px;
  flex: 1;
  text-align: center;
  background: #E8E8E8;
  text-transform: uppercase;
  color: #EA4444;
  font-size: 1rem;
  font-weight: bold;
  width: 50px;
  font-family: sans-serif;
}

.alert-danger {
  background: #FFE8E8;
  color: #EA4444;
  font-weight: bold;
  border-radius: 5px;
  font-size: .8rem;
}
</style>