// import roleDriver from '@/roles/roleDriver'

import Page from './Page.vue'
import DevicePage from './pages/DevicePage.vue'
import MonitoringPage from './pages/MonitoringPage.vue'


const Router = () => {

  return {
    path: '/devices',
    name: 'devices',
    // beforeEnter: roleDriver,
    component: Page,
    children: [
        { path: '', component: DevicePage },
        { path: '/devices/monitoring/:uuid', component: MonitoringPage}
    ]
  }

}

export default Router