<template lang="pug">
div(style="min-height: 850px" @click="closeFilter")
  card(title='Alert')
    setting-alert-modal(:alert="alert" v-if="showAlertModal" @close="showAlertModal = false" @success="this.getSettings")
    confirmation-modal(
      v-if="!!deletingAlert"
      @close="deletingAlert = null" 
      @confirm="onDeleteSetting"
      title="Delete Alert" 
      confirm-text="Yes, Delete"
    )
      p.m-0
        | Do you want to delete this alert?
    div.row
      .col-12
        .d-flex.justify-content-between.align-items-center
          div
            .btn-group.flow-29.shadow-sm(role="group")
              button.btn.btn-sm.btn-light.text-uppercase.px-4(:class="{'active': tabType == 'report'}" type="button" @click="tabType = 'report'") Report
              button.btn.btn-sm.btn-light.text-uppercase.px-4(:class="{'active': tabType == 'setting'}" type="button" @click="tabType = 'setting'") Setting
          .d-flex.justify-content-between.align-items-center.py-1
            div.mr-2
              span Alert Notification : 
            div.pt-1
              toggle-button(
                :sync="true"
                :margin="4"
                :width="40"
                :height="22" 
                :value="isShowAlert" 
                :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
                :labels="{'checked': 'On', 'unchecked': 'Off'}"
                @change="onSwitchShowAlert"
              )
      .col-12.pt-4
        .d-flex(v-if="tabType=='report'")
          div.w-100.pr-4
            matador-search#search-button(@input="onSearchReport" placeholder='Search' autocomplete='off')
            //- input.w-100.h-100.form-control.search-input.border-0(:style="table.report.search ? 'background: #fafafc' : 'background: #fafafc url(/icons/search-icon.png) no-repeat right 10px center;background-size: 26px;'" type='search' v-model="table.report.search" placeholder='Search' autocomplete='off')
          .p-0.d-flex
            .alert-filter-button
              matador-button.py-2.px-4(icon="fa fa-filter" @click="onToggleFilter") Filter
              .card(v-if="showFilter")
                .card-body.p-0
                  .card-section
                    p Value
                    .row
                      .col-md-6(v-for="(item, index) in filter.limit")
                        b-form-checkbox.py-0(@change="onFilterLimit" v-model="item[1]") {{ item[0] }}
                  .card-section
                    p Limit
                    vue-range-slider(
                      @drag-end="onSlideDragEnd" 
                      v-model="filter.value" 
                      :min="filter.minValue" 
                      :max="filter.maxValue" 
                      :enable-cross="true"
                    )
                  .card-section
                    p Activity
                    .row
                      .col-md-6(v-for="(item, index) in filter.activity")
                        b-form-checkbox.py-0(@change="onFilterActivity" v-model="item[1]") {{ item[0] }}
                    .pt-4.pb-2
                      matador-button(@click="getReports") Show
            matador-button.py-2.px-4.ml-2(icon="fa fa-sign-in" :outline="true" @click="onExport") Export
        .d-flex(v-if="tabType=='setting'")
          div.w-100.pr-4
            matador-search#search-button(@input="onSearchSetting" placeholder='Search' autocomplete='off')
          .p-0.d-flex
            matador-button.py-2.px-4.ml-2(icon="fa fa-plus" @click="onAddAlert") Add Alert

         
      .col-12.pt-4
        keep-alive
          div(v-if="tabType=='report'")
            .table-responsive.flow-29
              loader(:loading="saving")
                b-table.m-0(
                  ref="tableReport"
                  :busy.sync='table.report.isBusy' 
                  :filter='table.report.search' 
                  :items='table.report.data' 
                  :fields='table.report.fields' 
                  :perPage='table.report.perPage'
                  :show-empty="true" 
                  :currentPage="table.report.currentPage"
                  :totalRows="table.report.totalRows"
                  :filter-debounce="250"
                  @filtered="onFilteredReports"
                )
                template(#cell(status)="data")
                  svg(v-if="data.item.status === 'unread'" height="30" width="30")
                    circle(cx="15" cy="15" r="3" stroke="#9a2125" stroke-width="3" fill="#9a2125")
                //- template(#cell(last_update)="data")
                //-   span {{ data.item.last_update | datetime }}
                //- template(#cell(limit)="data")
                //-   span {{ data.item.limit.replace(/\sLimit/g, "") }}
            .pt-4.mt-3
              .d-flex.align-item-center.justify-content-between
                b-pagination(
                  v-model="table.report.currentPage"
                  :total-rows="table.report.totalRows"
                  :per-page="table.report.perPage"
                )
                div.d-flex.align-items-center
                  span.mr-2 Per&nbsp;Page:&nbsp;
                  b-dropdown(:text='table.report.perPage.toString()' variant='light')
                    b-dropdown-item(@click="table.report.perPage = 10") 10
                    b-dropdown-item(@click="table.report.perPage = 25") 25
                    b-dropdown-item(@click="table.report.perPage = 50") 50
                    b-dropdown-item(@click="table.report.perPage = 100") 100
        keep-alive
          div(v-if="tabType=='setting'")
            .table-responsive.flow-29
              b-table.m-0(
                ref="tableSetting"
                :busy.sync='table.setting.isBusy' 
                :filter='table.setting.search' 
                :items='table.setting.data' 
                :fields='table.setting.fields' 
                :perPage='table.setting.perPage'
                :show-empty="true" 
                :currentPage="table.setting.currentPage"
                :totalRows="table.setting.totalRows"
                :filter-debounce="250"
                @filtered="onFilteredSettings"
              )
                template(#cell(activity)="data")
                  span.activity.idle {{ data.item.activity }}
                template(#cell(last_update)="data")
                  span {{ data.item.last_update | datetime }}
                template(#cell(limit)="data")
                  span(style="white-space: pre-wrap;" v-if="data.item.expanded") {{ data.item.limit.replace(/\sLimit/g, "").replace(/,\s/g, "\n\n") }}
                  span(v-if="!data.item.expanded") {{ data.item.limit.replace(/\sLimit/g, "") }}
                template(#cell(value)="data")
                  span(style="white-space: pre-wrap;" v-if="data.item.expanded") {{ data.item.value.replace(/,\s/g, "\n\n") }}
                  span(v-if="!data.item.expanded") {{ data.item.value }}
                template(#cell(action)="data")
                  div
                    dropdown.dropdown-toggle-no-caret(tag='a' icon='mdi mdi-dots-vertical h4 text-dark m-0')
                      li.p-3.matador-text-03(@click="onEditAlert(data.item)") Edit
                      li.p-3.matador-text-03(@click="deletingAlert = data.item") Delete
                  //- .d-flex.justify-content-end
                    //- a.p-2(href="javascript:;" @click="onEditAlert(data.item)")
                    //-   img(height="24" src="@/assets/img/edit-icon.png")
                    //- a.p-2(href="javascript:;" @click="deletingAlert = data.item")
                    //-   img(height="24" src="@/assets/img/delete-icon.png")
                template(#cell(chevron)="data")
                  .text-right
                    a.text-dark.h3.m-0.p-2(href="javascript:;" @click="data.item.expanded = !data.item.expanded" tooltip="Expand limit data" v-if="data.item.expandable")
                      chevron-down(v-if="!data.item.expanded")
                      chevron-up(v-if="data.item.expanded")
            .pt-4.mt-3
              .d-flex.align-item-center.justify-content-between
                b-pagination(
                  v-model="table.setting.currentPage"
                  :total-rows="table.setting.totalRows"
                  :per-page="table.setting.perPage"
                )
                div.d-flex.align-items-center
                  span.mr-2 Per&nbsp;Page:&nbsp;
                  b-dropdown(:text='table.setting.perPage.toString()' variant='light')
                    b-dropdown-item(@click="table.setting.perPage = 10") 10
                    b-dropdown-item(@click="table.setting.perPage = 25") 25
                    b-dropdown-item(@click="table.setting.perPage = 50") 50
                    b-dropdown-item(@click="table.setting.perPage = 100") 100
        //- p {{ alertParams }}
</template>
<script>

import tableMixin from "@/mixins/table";
import Funnel from "vue-material-design-icons/Filter.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import SettingAlertModal from "../../../components/Matador/SettingAlertModal.vue";
import ConfirmationModal from "../../../components/Matador/ConfirmationModal.vue";
import { postRemoveAlertSetting, postToggleAlertNotification, postUnreadNotifications } from "../../../utils/api";
import Dropdown from "@/components/Dropdown.vue";

import {
  getNotificationSettings,
  getNotificationList,
} from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    Funnel,
    ChevronDown,
    ChevronUp,
    SettingAlertModal,
    ConfirmationModal,
    Dropdown
  },

  data() {
    return {
      saving:false,
      add: true,
      alert: null,
      showFilter: false,
      showAlertModal: false,
      notificationList: [],
      notificationSettings: [],
      currentExpandSetting: [],
      deletingAlert: null,
      user_id: null,
      // tabType: window.location.hostname === 'localhost' ? 'setting' : 'report',
      tabType: 'report',
      isShowAlert: false,
      filter: {
        value: [-50, 150],
        minValue: -50,
        maxValue: 150,
        enableValue: false,
        activeTab: window.location.hostname === 'localhost' ? 'value' : 'limit',
        counter: {
          limit: 0,
          value: 0,
          activity: 0
        },
        limit: [
          ['Overspeed', false],
          ['Idling Time', false],
          ['Signal Low', false],
          ['Engine Speed', false],
          ['Overheat', false],
          ['Voltage', false],
          ['Operating Mileage', false],
          ['Operating Time', false],
          ['Start Stop Counter', false],
        ],
        activity: [
          ['On', false],
          ['Idle', false],
          ['Off', false],
          ['Sleep', false],
        ]
      },
      table : {
        report: {
          data: [],
          isBusy: true,
          search: '',
          fields: [
            { key: 'status', label: '', sortable: false, class: '' },
            { key: 'vin', label: 'VIN', sortable: true, class: '' },
            { key: 'device_name', label: 'Device Name', sortable: true, class: '' },
            { key: 'limit', label: 'Limit', sortable: true, class: '' },
            { key: 'value', label: 'Value', sortable: true, class: '' },
            { key: 'foul', label: 'Foul', sortable: true, class: '' },
            { key: 'updated_at', label: 'Created At', sortable: true, class: '' },
          ],
          perPage: 10,
          currentPage: 1,
          totalRows: 0
        },
        setting: {
          data: [],
          isBusy: false,
          search: '',
          fields: [
            { key: 'vin', label: 'VIN', sortable: true, class: '' },
            { key: 'device_name', label: 'Device Name', sortable: true, class: '' },
            { key: 'limit', label: 'Limit', sortable: true, class: '' },
            { key: 'value', label: 'Value', sortable: true, class: '' },
            { key: 'action', label: '', sortable: false, class: '' },
            { key: 'chevron', label: '', sortable: false, class: '' },
          ],
          perPage: 10,
          currentPage: 1,
          totalRows: 0
        }
      }
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    alertParams(){
      const result = {
        limit_category: this.filter.limit.filter((item) => (item[1])).map((item) => (`${item[0].toLowerCase().replace(/\s/g, '_')}_limit`)),
        min_value: this.filter.value[0],
        max_value: this.filter.value[1],
        user_id: this.user_id,
        start: 0,
        limit: 1000,
        search: ''
      };
      return result;
    },
  },
  watch: {
    auth: {
      immediate: true,
      handler: function(newVal, oldVal){
        if(!!newVal) {
          this.isShowAlert = newVal.alert_notification === true;
          this.user_id = newVal.user_id;
          this.getReports();
          this.getSettings();
        }
      }
    },
    showFilter: {
      immediate: true,
      handler: function(newVal){
        if(!newVal){
          document.addEventListener('keydown', function(event) {
            event.stopImmediatePropagation();
          }, true);
        }
      }
    }
  },
  methods: {
    onToggleFilter(){
      this.showFilter = !this.showFilter;
    },
    onSlideDragEnd({ currentValue: value }){
      this.filter.value = value;
    },
    onSearchReport(value){
      this.table.report.search = value;
    },
    onSearchSetting(value){
      this.table.setting.search = value;
    },
    onFilterLimit(value){
      this.filter.counter.limit = this.filter.limit.filter(item => item[1]).length;
    },
    onFilterActivity(value){
      this.filter.counter.activity = this.filter.activity.filter(item => item[1]).length;
    },
    onAddAlert(){
      this.alert =null
      this.showAlertModal = true;
    },
    onEditAlert(x){
      this.alert = x;
      this.showAlertModal = true;

    },
    onSwitchShowAlert({ value }){
      this.isShowAlert = value;
      postToggleAlertNotification({
        alert_notification: value ? 1 : 0,
        user_id: this.$cookies.get('user_id')
      }).then(() => {
        this.$toast.success('Successfully switch alert notification.');
      });
    },
    onExport(){
      getNotificationList({...this.alertParams, download_report: 1}).then(({ data: { result: { url } } }) => {
        window.location = url;
      });
    },
    async getReports() {
      this.saving=true;
      this.showFilter = false;
      const { data: { result } } = await getNotificationList({...this.alertParams, start: 0, limit: 1000, user_id: this.$cookies.get('user_id')});
      this.table.report.data = [
        ...result.map(item => {
          item.activity = item.activity_status;
          return item;
        })
      ].sort((a, b) => {
        return a.updated_at.localeCompare(b.updated_at);
      }).sort((a, b) => {
        return a.status.localeCompare(b.statu);
      }).reverse();
      this.table.report.totalRows = this.table.report.data.length;
      this.saving=false;
    },
    getSettings() {
      getNotificationSettings().then(({ data: { result, message } }) => {
        this.table.setting.data = result.map(item => {
          item.activity = item.activity_status;
          item.raw_limits = (item.limit || []);
          item.expanded = false;
          item.expandable = Object.values(item.limit || {}).length > 1;
          item.value = Object.values(item.limit || {}).join(', ');
          item.limit = Object.keys(item.limit || {}).map(x => {
            return x.split('_').map(y => {
              return y[0].toUpperCase() + y.substring(1);
            }).join(' ');
          }).join(', ');
          // item.limit = (item.limit || []).map(x => {
          //   return Object.keys(x).map(y => {
          //     return y.split('_').map(z => {
          //       return z[0].toUpperCase() + z.substring(1);
          //     }).join(' ');
          //   }).join(', ')
          // }).join(', ');
          return item;
        });
        // this.onEditAlert(this.table.setting.data[0]);
        this.table.setting.totalRows = this.table.setting.data.length;
      });
    },
    onFilteredReports(filteredItems) {
      // console.log(filteredItems);
      this.table.report.currentPage = 1;
      this.table.report.totalRows = filteredItems.length;
    },
    onFilteredSettings(filteredItems) {
      // console.log(filteredItems);
      this.table.setting.currentPage = 1;
      this.table.setting.totalRows = filteredItems.length;
    },
    onFilter(){
      alert('Coming soon :s');
    },
    onDeleteSetting(){
      postRemoveAlertSetting({
        data_notification_id: this.deletingAlert.data_notification_id
      }).then(({ data: { code,messgae,data } }) => {
        if(code==200){
        this.getSettings()
        this.$refs.tableSetting.refresh();
        this.$toast.success('Successfully save settings');
        this.$emit('close');
        this.$emit('success');
        }
        else{
        this.$toast.error(messgae);
        this.$emit('close');
        }
      });
      this.deletingAlert = null;
    },
    closeFilter(event){
      // console.log(event.target.className);
    }
  },
  mounted(){
    setTimeout(() => {
      this.getReports();
    }, 5000);
  },
  beforeDestroy(){
    postUnreadNotifications(this.$cookies.get('user_id'));
  }
};
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem
}
.tab-filter{
  min-width: 200px;
  border-right: 1px solid #EAEAEC;
  padding-right: 0.5rem;
  .active {
    background-color: #e9eef3;
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      width: 2px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #1f548b;
    }
  }
}
div
  .badge-circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 4px;

    text-align: center;

    font: 10pt Arial, sans-serif;
    font: 10pt Arial, sans-serif;
    line-height: 9pt;
}
</style>