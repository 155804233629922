const {default: Api} = require('@/utils/api')
const entity = require("../entity")
const graph = require("../graph")

const initState = {
    stats: {},
    datas: {
        speed: {}, 
        temp: {},
        opt_mil: {},
        fuel: {},
        idle: {},
        opt_time: {}
    },
    graphs: {
        speed:      [ "Speed", entity.Units.speed, "bar" ], 
        temp:       [ "Temperature", entity.Units.temp, "bar" ],
        opt_mil:    [ "Operating Mileage", entity.Units.opt_mil, "bar" ],
        fuel:       [ "Fuel Consumption", entity.Units.fuel, "line"],
        idle:       [ "Idle Time", entity.Units.idle, "line"],
        opt_time:   [ "Operating Time", entity.Units.opt_time, "line"]
    },
}

module.exports = {
    namespaced: true,
    state: {...initState},
    actions: {
        collect_init_data({commit}, {success, error, done}) {
            Api.dashboard.collect_dashboard_stat()
            .then(response => {
                if (response.status !== 200)
                    throw new Error(response.data.message)
                
                commit('init_data', response.data.result)
                success(response)
            })
            .catch(e => {
                error(e)
                commit('log', {mod: 'collect_statistic', e}, {root:true})
            })
            .finally(done)
        },


        get_statistic({commit}, payload) {
            let {success, error, done, key, startDate, title} = payload
            
            Api.dashboard.collect_graph_chart(key, {start_date: startDate})
                .then(response => {
                    if (response.status !== 200)
                        throw new Error

                    let payload = {
                        data: response.data.result.data,
                        title,
                        key
                    }
                    
                    commit('set_graph', payload)
                    success(response)
                })
                .catch(e => {
                    error(e)
                    commit('log', {mod: 'get_statistic', e}, {root: true})
                })
                .finally(done)
        }
    },
    mutations: {
        init_data(state, payload) {
            let data = payload.data
            
            let stats = {}
            data.forEach(item => {
                stats[item.keys] = entity.StatEntity(item)
            })

            state.stats = {...stats}
        },

        set_graph(state, payload) {
            let datas = state.datas

            const dataFormat = {
                top: {
                    title: `Top ${payload.title}`,
                    data: []
                },
                avg: {
                    title: `Avg ${payload.title}`,
                    data: []
                }
            }
    
            let labels = []
            payload.data.forEach(item => {
                labels.push(item.label.split('-').pop())
                dataFormat.top.data.push(item.top)
                dataFormat.avg.data.push(item.avg)
            })

            datas[payload.key] = graph.graphData(labels, dataFormat, payload.title)
            datas[payload.key].type = state.graphs[payload.key] ? state.graphs[payload.key][2] : 'bar'
            state.datas = {...datas}
        }
    },
}