const {each: _each} = require("@/utils/utilities")
const {default: Api}  = require("@/utils/api")
const dayjs = require('dayjs')
const dashEntity = require("../entity")


const initState = {
    id: null,
    name: null,
    startDate: null,
    endDate: null,
    selectedVehicles: {},
    activeParams: {},
}

module.exports = {
    namespaced: true,
    state: {
        ...initState,
        parameters: {left: [],right: []}
    },

    actions: {
        get_detail({state,commit}, {success, error, done}) {
            
            const responseHandler = ({data, status}) => {
                if (status !== 200)
                    throw new Error

                commit('set_kpi', data.result)

                const kpi = data.result.data
                let result = {}
                result.isParamActive = Object.keys(kpi.parameters).length ? true : false
                result.isVehicleSelected = Object.keys(kpi.vehicles).length ? true : false
        
                success(result)
            }

            Api.dashboard.kpi_detail(state.id)
                .then(responseHandler)
                .catch(e => {
                    error(e)
                    commit('log', {mod: 'kpi:collect_detail_modal', e}, {root: true})
                })
                .finally(done)

        },

        collect_param({commit}) {

            const responseHandler = ({data, status}) => {
                if (status !== 200) return
                commit('set_parameter', data.result)
            }
        
            Api.dashboard.collect_kpi_params()
                .then(responseHandler)
                .catch(e => {
                    console.log(e)
                })
        },

        on_save({state, commit}, {success, error, done}) {
            let payload = {
                id: state.id,
                name: state.name,
                start_date: state.startDate,
                end_date: state.endDate,
                params: {},
                vehicles: []
            }
    
            _each(state.activeParams, id => {
                let elm = state.activeParams[id]
                if (elm) payload.params[id] = Number(elm.value)
            })
            
            _each(state.selectedVehicles, id => {
                if (id) payload.vehicles.push(id)
            })
            
            Api.dashboard.update_kpi(payload)
                .then(({data, status}) => {
                    if (!status === 200)
                        throw new Error
                    success(data)
                })
                .catch(e => {
                    error(e)
                    commit('log', {mod: 'kpi:on_save', e}, {root: true})
                })
                .finally(done)
        },

        on_delete_kpi({state, commit}, {success, error, done}) {
            
            const responseHandler = ({data, status}) => {
                if (status !== 200) 
                    throw new Error
                success(data)
            }

            Api.dashboard.delete_kpi(state.id)
                .then(responseHandler)
                .catch(e => {
                    error(e)
                    commit('log', {mod: `kpi:delete_kpi`, e}, { root:true })
                })
                .finally(done)
        }
    },

    

    mutations: {

        set_name(state, props) {
            state.name = props.data
        },

        init_date(state, props) {
            state.id = props.id
            state.name = props.name
            state.unit = props.unit
        },
        
        set_kpi_id(state, id) {
            state.id = id
        },

        set_date(state, props) {
            let type = props.type
            let date = props.date

            if (type === 'start')
                state.startDate = dayjs(date).format('YYYY-MM-DD')
            else
                state.endDate = dayjs(date).format('YYYY-MM-DD')
        },

        cleaning(state) {
            Object.keys(initState).forEach(key => {
                state[key] = null
            })

            state.selectedVehicles = {}
            state.activeParams = {}
        },

        set_kpi(state, props) {
            const kpi = props.data || {}

            state.id = kpi.id
            state.name = kpi.name
            
            state.startDate = dayjs(kpi.start_date).format('YYYY-MM-DD')
            state.endDate = dayjs(kpi.end_date).format('YYYY-MM-DD')
            const activeParams = {}

            _each(kpi.parameters, key => {
                let item = kpi.parameters[key]
                activeParams[item.id] = {
                    id: item.id,
                    value: item.max
                }
            })
            state.activeParams = {...activeParams}
        
            _each(kpi.vehicles, key => {
                let item = kpi.vehicles[key]
                state.selectedVehicles[key] = {
                    id: item.unit_id,
                    name: item.name
                }
            })
        },

        set_parameter(state, props) {
            const data = props.data || []

            let parameters = [[],[]]
            let total = data.length
            let split = Math.ceil(total/2)

            _each(data, item => {
                let param = {
                    id: item.id,
                    name: item.name,
                    value: 0,
                    unit: dashEntity.Units[item.key]
                }
                parameters[0].length < split ? parameters[0].push(param) : parameters[1].push(param)
            })

            let [left, right] = parameters
            state.parameters = {...state.parameters, left, right}
        },

        set_selected_vehicle(state, props) {
            const item = props.data
            
            if (!item) return
            let items = state.selectedVehicles
            items[item.id] = item

            state.selectedVehicles = {...items}
            props.callback({
                isVehicleSelected: Object.keys(state.selectedVehicles).length ? true : false
            })
        },

        delete_selected_vehicle(state, props) {
            const item = props.data
            if (!item) return

            delete state.selectedVehicles[item.id]
            state.selectedVehicles = {...state.selectedVehicles}
        },

        set_active_param(state, props) {
            let data = props.data || {}

            if (!data.id) return
            
            let value = Number(data.value) || 0
            let activeParams = state.activeParams
            activeParams[data.id] = {value}
            state.activeParams = {...activeParams}
            
            props.callback({
                isParamActive: Object.keys(state.activeParams).length ? true : false
            })
        },

        remove_active_param(state, props) {
            const data = props.data || {}
            if (!data.id) return

            let activeParams = state.activeParams
            delete activeParams[data.id]
            state.activeParams = {...activeParams} 
            
            props.callback({
                isParamActive: Object.keys(state.activeParams).length ? true : false
            })
            
        }
    }
    

}