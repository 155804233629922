<template lang="pug">
.modal-mask
  .modal-wrapper
    .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
      .modal-content
        .modal-header
          h5.title {{ t("KPI Operational Percentage ") }}
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;

        .modal-body.p-3
          .row
            .col-1
            .col-10
              label {{ t(`Operational percentage`) }} 
              div.d-flex.align-items-center
                input.form-control.mr-2(:disabled="loading" v-model="threshold")
                span %
            .col-1

        .modal-footer
          button.btn.btn-light(:disabled="loading" @click="$emit('close')") {{ t('cancel') }}
          button.btn.btn-primary(:disabled="loading" @click="updateThreshold") {{ loading ? t('Loading...') : t('update') }}
        
</template>

<script>
import Api from '@/utils/api'

export default {
  props: ['open'],

  data() {
    return {
      loading: false,
      threshold: 0,
    }
  },

  methods: {

    getThreshold() {
      if (this.loading) return
      this.loading = true

      Api.dashboard.get_threshold()
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          
          this.threshold = Number(data.result.data.value.threshold)
        })
        .catch(e => {
          let err = e.resposne ? e.response.data :e
          this.$toast.error(err.message || 'Failed to load setting data')
          this.$store.commit('log', {mod: 'kpi:load_setting', e})
        })
        .finally(() => this.loading = false)
    },

    updateThreshold() {
      if (this.loading) return 
      this.loading = true

      Api.dashboard.update_threshold(this.threshold)
      .then(({data, status}) => {
        if (status !== 200) 
          throw new Error

        this.$toast.success(data.message || 'Successfully update threshold')
      })
      .catch(e => {
        let err = e.response ? e.response.data : e
        this.$toast.error(err.message || 'Failed update threshold')
      })
      .finally(() => this.loading = false)

    }

  },


  watch: {
    open: {
      immediate: true,
      handler(value) {
        if (!value) return
        
        this.getThreshold()
      }
    }
  }
}
</script>