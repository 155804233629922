<template lang="pug">
div.mb-3(:class="(enabled ? 'active' : 'text-muted')")
  .row.d-flex.align-items-center(style="font-size: 0.8rem")
    
    .col-5
      .d-flex.cursor-pointer(@click="onToggleCheckbox")
        i.mr-2(:class="(enabled ? 'mt mt-circle-dot' : 'mt mt-circle-bare')")
        span {{ data.name }}
    
    .col-7
      .d-flex.align-items-center
        input.form-control.mr-2(
          style="width: 100px" 
          :ref="data.id"
          placeholder="0" 
          :value="value"
          :disabled="!enabled"
          @blur="onBlur"
        )
        span(v-html="data.unit")
    
</template>

<script>
import {mapState} from 'vuex'
import {NS} from '../../../store'
export default {
    props: [ "data" , "modalLoading"],

    computed: {
      ...mapState(NS.alert, ['activeParams']),

      enabled() {
        if (this.modalLoading) return false
        return typeof this.activeParams[this.data.id] !== 'undefined' ? true: false
      },

      value() {
        let item = this.activeParams[this.data.id]
        if (typeof item === "undefined") return 0
        return Number(item)
      }

    },

    methods: {
      onToggleCheckbox() {
        this.$store.commit(`${NS.alert}/toggle_param`, {data: this.data})
      },

      onBlur(e) {
        const payload = {
          data: {
            id: this.data.id,
            value: e.target.value
          }
        }

        this.$store.commit(`${NS.alert}/set_param`, payload)
      },

    },


}
</script>