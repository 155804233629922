<template lang="pug">
div
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
        .modal-content
          .modal-header.d-flex.align-items-center
            div
              h5.mb-0.title {{ t('KPI Fleet Overview') }}
            
            div.d-flex.align-items-center
              div.btn-group
                button.btn.btn-sm.btn-success(v-if="id" @click="cleaning")
                  i.fa.fa-plus
                  | {{ t(`Create new`) }}
                  
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="close"
              )
                span.pr-3(aria-hidden="true") &times;

          .modal-body.p-4
            loader(:loading="loading")
              .row
                .col-lg-12.mb-3
                  label {{ t(`Fleet Name`) }}
                  input.form-control(:placeholder="t(`Fleet Name`)" @blur="setName" :value="name")

                .col-lg-12.d-flex.mb-3
                  .mr-2
                    label {{ t(`Start Date`) }}
                    datepicker(
                      input-class="form-control datepicker" 
                      @selected="onSelectStart" 
                      :value="startDate"
                      ref="startDate"
                      placeholder="Select start date"
                    )

                  .mr-2
                    label {{ t(`End Date`) }}
                    datepicker(
                      input-class="form-control datepicker" 
                      @selected="onSelectEnd"
                      :value="endDate"
                      ref="endDate"
                      placeholder="Select end date"
                    )

                .col-lg-12.mb-3
                  label {{ t(`Parameters`) }}
                  span.text-muted.ml-3 ( {{ t('max value') }} )
                  
                  .row(:class="partialHide ? 'partial-hide' : ''")
                    .col-6
                      param-field(
                        v-for="param in parameters.left"
                        :key="param.id"
                        :data="param"
                        :active="isActive(param.id)"
                        :value="activeParams[param.id] ? activeParams[param.id].value : ''"
                        @activateParam="toggleParam"
                      ) 

                    .col-6
                      param-field(
                        v-for="param in parameters.right"
                        :key="param.id"
                        :data="param"
                        :active="isActive(param.id)"
                        :value="activeParams[param.id] ? activeParams[param.id].value : ''"
                        @activateParam="toggleParam"
                      ) {{ activeParams[param.id] || 'test' }}

                  .d-flex.justify-content-center.mt-4(v-if="showHideButton")
                    button.btn.btn-primary(
                      style="padding: 2px 8px;"
                      @click="partialHide = !partialHide"
                    ) {{ partialHide ? t(`Show more`) : t(`Hide parameter`)}}

                .col-lg-12.mb-3
                  div
                    label {{ t(`Vehicle`) }}
                    autocomplete.mb-2(
                      :options="vehicles"
                      @selected="onSelectVehicle"
                      @filter="onSearchVehicle"
                      :maxItem=10
                      :placeholder="t(`Please select vehicle`)"
                    )
                    
                    .mt-chip-wrapper(v-if="Object.keys(selectedVehicles).length")
                      div.btn-group.mr-2(
                        v-for="id in Object.keys(selectedVehicles)" 
                        v-if="isChipShowed(id)"
                        :key="id"
                      )
                        span.btn.btn-sm.mt-badge {{ selectedVehicles[id].name }}
                        span.btn.btn-sm.mt-badge.mt-badge-close(@click="removeVehicle" :data-id="id") x
                        
            .modal-footer.d-flex.justify-content-between.align-items-center.px-0
              div
                button.btn.btn-danger(v-if="!loading" @click="onDelete" :disabled="loading") {{ t(`Delete`) }}
              div
                button.btn.btn-light.mr-2(@click="close") {{ t(`Cancel`) }}
                button.btn.btn-primary(@click="onSave" :disabled="loading || !isClean") 
                  span(v-if="loading") {{ t('Loading...') }}
                  span(v-else) {{ id ? t(`Update`) : t('Save') }}

  confirmation-modal(
    v-if="deleteConfirm"
    title="Delete KPI Confirmation" 
    confirm-text="Delete"
    @close="deleteConfirm = null" 
    @confirm="onDeleteConfirm"
  )
    p.m-0
      | {{ t(`Do you want to delete this generator device?`) }}

</template>

<script>

import ConfirmationModal from "@/components/Matador/ConfirmationModal.vue";
import {mapState} from 'vuex'
import {NS} from '../../../store'
import Datepicker from 'vuejs-datepicker'
import ParamField from './ParamField.vue'
import debounce from 'debounce'
import Api from '@/utils/api'
import {each as _each} from '@/utils/utilities'

export default {
  props: ["parameterList", "dataKpi"],

  components: {
    ParamField,
    Datepicker,
    ConfirmationModal
  },

  data() {
    return {
      deleteConfirm: false,
      isParamActive: false,
      isVehicleSelected: false,

      vehicles: [],
      searchVehicleLoading: false,
      partialHide: true,
      deleteLoading: false,
      
      loading : false
    }
  },

  methods: {
    setName(e) {
      this.$store.commit(`${NS.kpi_modal}/set_name`, {data: e.target.value})
    },

    close() {
      this.$store.commit(`${NS.kpi_modal}/cleaning`)
      this.$emit('close')
    },
    
    isActive(id) {
      let status = typeof this.activeParams[id] !== "undefined" ? true : false
      return status
    },

    toggleParam(id, val) {
      if (!id) return

      const callback = (result) => {
        this.isParamActive = result.isParamActive
      }

      if (this.activeParams[id]) {
         this.$store.commit(`${NS.kpi_modal}/remove_active_param`, {data: {id}, callback})
      } else {
        let params = {
          id: id,
          value: val
        }
        this.$store.commit(`${NS.kpi_modal}/set_active_param`, {data: params, callback})
      }
    },

    onSelectStart(date) {
      this.$store.commit(`${NS.kpi_modal}/set_date`, {type: 'start', date})
    },

    onSelectEnd(date) {
      this.$store.commit(`${NS.kpi_modal}/set_date`, {type: 'end', date})
    },

    collectDetail() {
      if (this.loading) return
      this.loading = true

      const callback = {
        success: (result) => {
          this.isParamActive = result.isParamActive
          this.isVehicleSelected = result.isVehicleSelected
        },
        error: () => {},
        done: () => {
          this.loading = false
        }
      }
      this.$store.dispatch(`${NS.kpi_modal}/get_detail`, {...callback})
    },

    cleaning() {
      this.$store.commit(`${NS.kpi_modal}/cleaning`)
      
      this.isParamActive = false
      this.isVehicleSelected = false
    },

    onSave() {
      if (this.loading) return
      this.loading = false

      const callback = {
        success: data => {
          this.$toast.success(data.message || 'KPI Successfully Created')
          this.$emit('success')
          this.$emit('close')
        },
        error: (e) => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to store kpi')
        },
        done: () => {
          this.loading = false
        }
      }
      this.$store.dispatch(`${NS.kpi_modal}/on_save`, {...callback})
    },

    onDelete() {
      this.deleteConfirm = true
    },

    onDeleteConfirm() {
      if (this.deleteLoading) return
      this.deleteLoading = false

      const callback = {
        success: () => {
          this.deleteConfirm = false
          this.$emit('success')
          this.$emit('close')
        },
        error: (e) => {
          let err = e.response ? e.response.data : e
          this.$toast.error(err.message || 'Failed to delete kpi')
        },
        done: () => {
          this.deleteLoading = false
        }
      }
      
      this.$store.dispatch(`${NS.kpi_modal}/on_delete_kpi`, {...callback})
    },

    onSelectVehicle(item) {
      if (!item.id) return

      this.$store.commit(`${NS.kpi_modal}/set_selected_vehicle`, {data: {id: item.id, name: item.name}, callback: (result) => {
          this.isVehicleSelected = result.isVehicleSelected
      }})
    },

    isChipShowed(id) {
      const chip = this.selectedVehicles[id]
      return chip && chip.name
    },

    removeVehicle(e) {
      const id = e.target.getAttribute('data-id')
      this.$store.commit(`${NS.kpi_modal}/delete_selected_vehicle`, {data: {id}})
      this.isVehicleSelected = Object.keys(this.selectedVehicles).length ? true : false
    },

    onSearchVehicle: debounce(function(q) {
      
      if (this.searchVehicleLoading || !typeof q === "undefined") return
      this.searchVehicleLoading = true

      Api.dashboard.search_vehicle({q})
      .then(({data, status}) => {
        if (status !== 200)
          throw new Error

        let vehicles = []
        data.result.data.forEach(item => {
          if (!this.selectedVehicles[item.id])
            vehicles.push(item)
        })
        
        this.vehicles = vehicles
      })
      .catch(e => {
        let err = e.response ? e.response.data : e
        this.$toast.error(err.message || 'Failed to search vehicle')
      })
      .finally(() => this.searchVehicleLoading = false)

    }, 500),

  },

  computed: {
    ...mapState(NS.kpi_modal, [
      'id', 
      'name',
      'startDate',
      'endDate',
      'selectedVehicles',
      'activeParams',
      'parameters'
    ]),
    
    vehicleList() {
      return Object.keys(this.selectedVehicles)
    },

    isClean() {
      return this.name
        && this.startDate 
        && this.endDate
        && this.isParamActive
        && this.isVehicleSelected
    },

    showHideButton() {
      return this.parameters.left.length > 5
    }
  },

  watch: {
    dataKpi: {
      immediate: true,
      handler(value) {
        
        if (!value || !value.id) return

        this.$store.commit(`${NS.kpi_modal}/init_date`, value)
        
        this.kpi_id = value.id
        this.collectDetail()
      }
    },

  },

  mounted() {
    if (!this.vehicles.length)
      this.onSearchVehicle(null)
  }
}
</script>

<style scoped>
.partial-hide {
  max-height: 210px;
  overflow: hidden;
}

.mt-badge {
  background: #d4d4d4!important; 
  border-color: #cbcbcb!important;
  padding: 2px 10px;
  color: #6e6e6e;
}
.mt-badge-close {
  background: #cbcbcb;
}

</style>