<template lang="pug">
div(style="min-width: 600px")
  device-form-modal(
    ref="form"
    @close="showAdd=false"
    :visible="showAdd"
    :currentDevice="currentDevice"
    @onSave="onDeviceChanged"
    @onDelete="onDeviceDelete"
  )

  card(title='Devices')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Search Devices' autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Add Device
            
        keep-alive
          .table-responsive
            bs-table(
              ref='table'
              :columns="table.columns",
              :options="table.options",
              :data="devices",
              @on-post-body="vueFormatterPostBody"
            )
  
  confirmation-modal(
    v-if="showDeleteModal"
    @close="showDeleteModal = false" 
    @confirm="onDeleteConfirmed" 
    title="Delete Device"
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do yo want to delete this device?

</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import DeviceFormModal from "../components/DeviceFormModal.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import {tableOption} from './table'
import {getDeviceAll} from "@/utils/api";
import Api from '@/utils/api';

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    DeviceFormModal,
    ConfirmationModal
  },

  data() {
    return {
      currentDevice: {},
      position: '',
      inputData: {
        username: '',
        fullname: '',
        email: '',
        position: '',
        organization: '',
        password: '',
        password_confirm: '',
      },
      showAdd: false,
      userList: [],
      devices: [],
      positionList: [],
      organizationList: [],
      hidepassword: true,
      hideconfirmpassword: true,
      showDeleteModal: false,
      table: tableOption(this),
    };
  },
  methods: {

    onMonit(device) {
      this.$router.push(`/devices/monitoring/${device.imei}`)
    },

    clickAdd() {
      this.currentDevice = null;
      this.showAdd = true;
    },

    clickEdit(device){
      this.currentDevice = device;
      this.showAdd = true;
    },

    clickDelete(device){
      this.showDeleteModal = true
      this.currentDevice = device;
    },

    onDeleteConfirmed() {
      
      Api.device.remove(this.currentDevice)
        .then(response => {
          if (response.status !== 200)
            throw new Error("Failed to delete device")
          
          this.currentDevice = null
          this.showDeleteModal = null
          this.$refs.table.refresh();
        })
        .catch(e => {
          let message = e.message || 'Failed to delete message'
          return this.$toast.error(message)
        })
        .finally(() => {
          this.loading = false
        })
      
    },
    
    fetchDeviceList(params) {
      if (this.loading) return

      this.loading = false
      
      let order = params.data.order || 'asc'
      let sort = params.data.sort || 'name'
      
      let query =  {
        sort: `${sort}.${order}`,
        q: params.data.search
      }

      Api.device.list(query)
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error

          let rows = data.result.data
          let pagination = data.result.pagination
          
          if (!rows || !rows.length) return params.success(false)

          this.devices = rows
          let resource = {
            rows: this.devices,
            total: pagination.total_data || 0,
            totalNotFiltered: pagination.total_data || 0
          }
          setTimeout(() => params.success(resource), 500)
        })
        .catch(e => {
          params.success(true)
        })
        .finally(() => {
          this.loading = false
        })

    },

    onDeviceChanged(device) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open('Successfully save device data.');
    },

    onDeviceDelete() {
      this.$refs.table.refresh();
      this.$toast.open('Successfully delete device data.');
    },
    
  },

  mounted() {
    // reset search if clear
    var that = this;
    window.$('.search input').on('search', function() {
      if(!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
    
  },

};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem
}
.tab-filter{
  min-width: 200px;
  border-right: 2px solid #EAEAEC;
  padding-right: 0.5rem;
}
.badge-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 4px;

    text-align: center;

    font: 10pt Arial, sans-serif;
    font: 10pt Arial, sans-serif;
}
.p-viewer{
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor:pointer;
}
</style>
