const {default: Api} = require('@/utils/api')

const initialState = {
    loading: false,
    vehicle: {
        imei: null,
        name: '',
        device_name: '',
        thumb: null,
    },
    dtc: {
        code: '',
        description: ''
    }
}

module.exports = {
    namespaced: true,
    state: {...initialState},
    actions: {
        get_latest({state, commit}, inst) {
            if (state.loading) return
            
            commit('set_loading', {loading: true})
            const responseHandler = ({data, status}) => {
                if (status !== 200) 
                    throw new Error

                commit('set_vehicle', {data: data.result.data[0]})
            }

            const query = {
                page: inst.page || 1
            }
            Api.dashboard.latest_obd(query)
                .then(responseHandler)
                .catch(e => {
                    commit('log', {mod: 'on_board:latest_obd', e}, {root: true})
                })
                .finally(() => commit('set_loading', { loading: false }))
            
        }
    },

    mutations: {
        set_loading(state, props) {
            state.loading = props.loading
        },

        set_vehicle(state, props) {
            const data = props.data || {}
            state.vehicle = {
                imei: data.imei,
                name: data.name || '-',
                device_name: data.device_name || '-',
                thumb: data.thumb
            }
            
            if (data.codes) {
                const dtc = data.codes[0] || {}
                state.dtc = {
                    code: dtc.dtc,
                    description: dtc.description
                }
            }
        }
    }
    
}