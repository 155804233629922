const OnBoard = require("./on_board_info")
const Main = require("./main")
const KpiModal = require("./kpi_modal")
const Alert = require('./alert_status')
const Graph = require('./graph')

const NS = {
    main: 'dashboard/main',
    on_board: 'dashboard/on_board',
    kpi_modal: 'dashboard/kpi_modal',
    alert: 'dashboard/alert_status',
    graph: 'dashboard/graph'
}

const DashboardStore = {
    namespaced: true,
    modules: {
        main: Main,
        on_board: OnBoard,
        kpi_modal: KpiModal,
        alert_status: Alert,
        graph: Graph
    }
}

module.exports = {
    NS,
    DashboardStore
}
