<template lang="pug">
div.flex-grow-1.mx-2.mt-5.pl-3.mt-graph-info
  p.d-flex.justify-content-between
    span {{ t(`Peak`) }}
    span {{ peak }} <span v-html="unit"></span>

  p.d-flex.justify-content-between
    span {{ t(`Lowest`) }}
    span {{ lowest }} <span v-html="unit"></span>

  p.d-flex.justify-content-between
    span {{ t(`Total`) }}
    span {{ total }} <span v-html="unit"></span>

  p.d-flex.justify-content-between
    span {{ `Expected` }}
    span {{ expected }} <span v-html="unit"></span>

  hr
  div.d-flex.justify-content-between.align-items-center
    span {{ t(`Conclusion`) }}
    span.mt-badge(:class="badges[result]") {{ badges[result] }}

  div.d-flex.justify-content-center.mt-3
    span.text-muted.text-center(style="font-style: italic; font-size: 0.8rem;") ( {{ t('Average per day is') }} 
      strong {{ dailyAvg }} 
      span(v-html="unit")
      | )

</template>

<script>
import entity from '../entity'
import {numberFormat} from '@/utils/utilities'

export default {
  props: ["data", "stat"],

  data() {
    const badges = ['bad', 'enough', 'perfect']

    return {
      unit: '',
      badges,
      peak: 0,
      lowest: 0,
      total: 0,
      expected: 0,
      result: 0,
      dailyAvg: 0,
      activeStat: entity.StatEntity(),
    }
  },

  methods: {
    inference(total) {
      if (!this.activeStat || this.activeStat.enough) return 

      if (this.activeStat.enough && total > this.activeStat.enough[0] && total <= this.activeStat.enough[1]) {
        return this.result = 1
      } 

      if (this.activeStat.perfect && total > this.activeStat.perfect[0] && total <= this.activeStat.perfect[1]) {
        return this.result = 2
      }

    },
    
    conclusion(value) {
      let top = []
      let avg = []

      let dailyAvg = 0
      
      if (value.data) {
        if (value.type === 'bar') {
          top = value.data.datasets[1].data
          avg = value.data.datasets[0].data

          avg.forEach(item => dailyAvg += item)
          dailyAvg = dailyAvg/avg.length
          this.dailyAvg = numberFormat.format(Number(dailyAvg.toFixed(2)))

        } else {

          top = value.data.datasets[0].data
        }
      }

      this.unit = value.unit
      this.peak = numberFormat.format(Math.max(...top))
      this.lowest = numberFormat.format(Math.min(...top))

      let total = 0
      top.forEach(num => total += num)
      this.inference(total)

      this.total = numberFormat.format(total)
    }

  },


  watch: {

    data: {
      immediate: true,
      handler(value) {
        setTimeout(() => this.conclusion(value), 500)
        
      }
    },

    stat: {
      immediate: true,
      handler(value) {
        if (!value) return

        this.activeStat = value
        this.expected = value.expected || 0
      }
    }
  }
  
}
</script>

<style scoped lang="scss">
.mt-graph-info {
  &> p {
    span {
      font-size: 0.9rem;
    }

    span:first-child {
      color: #8B90A0;
    }
    span:last-child {
      font-weight: bold;
    } 
  }

  .mt-badge {
    padding: 1px 10px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: bold;

    &.bad {
      color: #992024;
      background: #F0DDDE;
    }

    &.enough {
      color: #1F548B;
      background: #DDE5ED;
    }

    &.perfect {
      color: #0CCA4A;
      background: #DBF7E4;
    }
  }
}

</style>