<template lang="pug">
div
  .card.p-4
    .card-header.p-0.pb-2
      .d-flex.justify-content-between.align-items-center
        h5.font-weight-bold.card-title
          | {{ t(`Today's Alert`) }}

        .btn-header-wrapper.d-flex
          pop-info(:title="t(`Today's Alert`)" data-id="today-alert") 
              | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          span(@click="settingOpen = true") <i class="mt mt-gear"></i>

    .card-body.p-0.pt-2
      loader(:loading="loading" style="height: 100px")
        div(style="height: 120px; overflow: auto; padding-top: 15px")
          .alert-item( 
            v-for="alert in alertItems" 
            :key="alert.id" 
            v-if="alert.show" 
          )
            .d-flex.flex-column.justify-content-center.alter-items-center
              .alert-content(@click="openAlertList(alert)")
                .alert-icon
                  i(:class="alert.icon")
                .alert-counter {{ alert.count }}
              .alert-label.mt-2 {{ alert.label }}

  
  alert-setting-modal(
    v-if="settingOpen"
    @close="settingOpen = false"
    @success="collectAlert"
  )

  alert-list-modal(
    v-if="listOpen"
    @close="listOpen = false"
    :alertType="selectedAlertType"
  )

</template>

<script>
import {mapState} from 'vuex'
import AlertSettingModal from '../components/modals/AlertSettingModal/Alert.vue'
import AlertListModal from '../components/modals/AlertListModal.vue'
import PopInfo from '../components/PopInfo.vue'

import {NS} from '../store' 
import {each as _each} from '@/utils/utilities'

export default {
  components: {
    PopInfo,
    AlertSettingModal,
    AlertListModal
  },

  data() {
    return {
      
      loading: false,
      settingOpen: false,
      listOpen: false,
      selectedAlertType: null
    }
  },

  methods: {

    openAlertList(item) {
      this.selectedAlertType = item.id
      this.listOpen = true
    },
    
    collectAlert() {
      if (this.loading) return 
      this.loading = true

      const callback = {
        success: () => {},
        error: () => {},
        done: () => {
          this.loading = false
        }
      }

      this.$store.dispatch(`${NS.alert}/collect_alert`, callback)
    }
  },

  computed: {
    ...mapState(NS.alert, ['alerts']),
    alertItems() {
      return Object.values(this.alerts)
    }
  },

  mounted() {
    this.collectAlert()
  }

}
</script>

<style scoped lang="scss">
.alert-item {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 100px;
  text-align: center;
  margin-bottom: 20px;

  .alert-content {
    background: #F1F5F8;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    position: relative;
    margin: auto;
    

    .alert-icon {
      height: 100%;
      display: flex;
      font-size: 1.2rem;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      
    }

    .alert-counter {
      background: #ff0000;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      text-align: center;
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 0.7rem;
      color: #ffffff;
    }
  }
  
  .alert-label  {
    font-size: 0.8rem;
  }
  
}

.btn-header-wrapper {
  font-size: 1rem;

  span {
    cursor: pointer;
  }

  span:first-child {
    margin-right: 10px;
  }
}

</style>