import {IsFeatureActive} from '@/utils/rbac'
import Middleware from '@/roles/roleDashboard'

import Page from './v3.1/Page.vue'
import OldPage from './v3/Dashboard.vue'


const Router = () => {

  return {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: Middleware,
    component: IsFeatureActive('analytic_dashboard') ? Page : OldPage,
  }

}

export default Router