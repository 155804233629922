const { each: _each } =require("@/utils/utilities")

module.exports = {
  Units: Units(),
  ParamColor: ParamColor(),
  AlertTitles: AlertTitles(),
  
  NotifListEntity,
  KpiDataEntity,
  StatEntity,
  ParameterEntity
}

function Units() {
  return {
    speed: 'km/H',
    temp: "&deg;C",
    opt_mil: 'km',
    fuel: 'L',
    idle: 'h',
    opt_time: 'h',
    voltage: 'V',
    engine_speed: 'km/H',
    engine_temperature: "&deg;C"
  }
}


function ParamColor() {
  return {
    speed: '#6285ab',
    temp: '#ffe398',
    fuel: '#1F548B',
    idle: '#ffcacc',
    opt_mil: '#8B90A0',
    opt_time: '#a8f2c0',
    voltage: '#7b7b7b',
    engine_speed: '#917835',
    engine_temperature: '#c4dcff'
  }
}

function StatEntity(data) {
  data = data || {}

  return {
    id: data.dash_param_id,
    org: data.organization_id,
    unit: data.unit,
    title: data.title,
    bad: [data.min_bad, data.max_bad],
    enough: [data.min_enough, data.max_enough],
    perfect: [data.min_perfect, data.max_perfect],
    expected: data.expected
  }
}

function ParameterEntity(data) {
  data = data || {}

  return {
    id: data.id,
    key: data.key,
    name: data.name,
    color: data.color
  }
}

function KpiDataEntity(data) {
  data = data || {}

  let kpi = {
    id: data.id,
    name: data.name,
    start_date: data.start_date,
    end_date: data.end_date,
    parameters: data.parameters || {},
    total_all: 0,
    chart: {
      labels: [],
      data: [],
      color: []
    }
  }
  
  let total = {total: 0, max: 0}
  const colors = ParamColor()
  
  _each(kpi.parameters, key => {
    let item = kpi.parameters[key]
    item.color = colors[key]

    kpi.chart.labels.push(item.name)
    kpi.chart.data.push(Number(item.avg.toFixed(2)))
    kpi.chart.color.push(item.color)
    
    total.total += item.avg
    total.max += item.avg < item.max ? item.max : 0
  })

  kpi.total_all = total.max > 0 ? (total.total/total.max) * 100 : 
  kpi.total_all = Number(kpi.total_all.toFixed(2))
  return kpi
}


function AlertTitles() {

  // following format
  //    - [ <Field>, <Title>, <Unit>, <icon>]

  return {
    overspeed: ['overspeed_limit', 'Overspeed', 'km/H', 'mt mt-speed'],
    overheat: ['overheat_limit', 'Overheat', '&deg;C', 'mt mt-speed'],
    signal_low: ['signal_low_limit', 'Signal', '', 'mt mt-speed'],
    start_count: ['start_count_limit', 'Start Count', '', 'mt mt-speed'],
    mileage_low: ['operate_mileage_low_limit', 'Opt.Mil Low', 'Km', 'mt mt-speed'],
    mileage_high: ['operate_mileage_high_limit', 'Opt.Mil High', 'Km', 'mt mt-speed'],
    operate_time_low: ['operate_time_low_limit', 'Opt.Time Low', '', 'mt mt-speed'],
    operate_time_high: ['operate_time_high_limit', 'Opt.Time High', '', 'mt mt-speed'],
    idle_time: ['idle_time_limit', 'Idle', '', 'mt mt-speed'],
    voltage_low: ['voltage_low_limit', 'Voltage', 'V', 'mt mt-speed'],
    voltage_high: ['voltage_high_limit', 'Voltage', 'V', 'mt mt-speed'],
    engine_high_speed: ['engine_high_speed_limit', 'Eng.High Speed', '', 'mt mt-speed'],
    engine_high_load: ['engine_high_load_limit', 'Eng.High Load', '', 'mt mt-speed'],
    fuel_low: ['fuel_low_limit', 'Fuel Low', 'L', 'mt mt-speed'],
    oil_low_mileage: ['oil_low_mileage_limit', 'Oil Low', '', 'mt mt-speed'],
    oil_low_mileage_check: ['oil_low_mileage_check', 'Oil Low Check', '', 'mt mt-speed'],
  }
}

function NotifListEntity(data) {
  data = data || {}

  return { 
    id: data.id, 
    thumb: data.thumb,
    name: data.name || '-', 
    vin: data.vin || '-', 
    driver: data.driver || '-', 
    location: data.location || '-', 
    time: data.time, 
    value: data.value || 0
  }
}