<template lang="pug">
div(@click="eventDetail(timeline)")
  div(:class="position === 'bottom' ? 'timeline-card bottom' : 'timeline-card top'")
    div
      div.d-flex(v-if="position === 'bottom'" style="margin-bottom: 10px;")
        img(v-if="isActive" :src="require(`@/assets/img/driver/timeline-bottom-active.svg`)")
        img(v-else :src="require(`@/assets/img/driver/timeline-bottom.svg`)")
        div.gray-line(v-if="!isLast")

      div.content-wrapper(style="cursor: pointer")
        .content(:class="isActive ? 'active' : ''")
          div.d-flex
            span Location: 
            span {{latitude}}, {{longitude}}

          div.d-flex
            span Time: 
            span {{ time }}

          div.d-flex
            span Battery:
            span {{battery_volt}}% {{ battery_level }}VDC

      div.d-flex(v-if="position === 'top'" style="margin-top: 10px;")
        img(v-if="isActive" :src="require(`@/assets/img/driver/timeline-top-active.svg`)")
        img(v-else :src="require(`@/assets/img/driver/timeline-top.svg`)")
        div.gray-line(v-if="!isLast" style="margin-top:20px;")

</template>

<script>
export default {
  props: ['timeline', 'total', 'last', 'index', 'eventDetail'],

  data() {
    return {
      latitude: 0,
      longitude: 0,
      position: 'top',
      date: '',
      time: '',
      battery_volt: 100,
      battery_level: 3,
      isActive: false,
      isLast: false
    }
  },

  methods: {
    detail() {
      
    }
  },

  watch: {
    timeline: {
      immediate: true,
      handler: function(value) {
        Object.keys(value).forEach(key => {
          this[key] = value[key]
        })

        if (this.last.timestamp && this.timeline.timestamp)
          this.isActive = this.last.timestamp === this.timeline.timestamp

        this.isLast = this.index === this.total
      }
    }
  },
  
}
</script>

<style lang="scss" scoped>
  .timeline-card {
    height: 245px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;

    &.bottom {
      justify-content: end;
    }

    &.top {
      justify-content: start;
    }

    .content-wrapper {
      min-height: 88px;
    }

    &.finish {
      .content-wrapper {
        margin-left: -25px;
      }
      .content {
        min-width: 0!important;
        text-align: center;
      }

    }

  }

  .gray-line {
    color: #c2c2c2;
    border-top: 3px solid #c2c2c2;
    display: block;
    flex-grow: 1;
    margin-top:10px;
    margin-left: 10px;
  }

  .content {
    background: #F5F5F5;
    max-width: 250px;
    min-width: 180px;
    border-radius: 10px;
    font-size: .6rem;
    padding: 15px;
    

    &.active {
      background: #E6FFDF;
    }

    span {
      color: #828282;
    }

    span:first-child {
      width: 50px;
      color: #333333;
      font-family: sans-serif;
      font-weight: bold;
      display: inline-block;
    }
  }
</style>