<template lang="pug">
div.mb-3(:class="(enabled ? 'active' : 'text-muted')")
  .row.d-flex.align-items-center(style="font-size: 0.8rem")
    .col-5
      .d-flex.cursor-pointer(@click="onToggleCheckbox")
        i.mr-2(:class="(enabled ? 'mt mt-circle-dot' : 'mt mt-circle-bare')")
        span {{ name }}
    .col-7
      .d-flex.align-items-center
        input.form-control.mr-2(
          style="width: 100px" 
          :ref="id"
          placeholder="0" 
          type="number"
          v-model="val"
          :disabled="!enabled"
          @blur="onBlur"
        )
        span(v-html="data.unit")
</template>

<script>
import {mapState} from 'vuex'
import {NS} from '../../../store'

export default {
  props: ['active', 'value', 'data'],

  data() {
    return {
      val: 0,
    }
  },

  
  watch: {
    data(value) {
      this.$store.commit(`${NS.kpi_modal}/init_data`, value)
    },

    value: {
      immediate: true,
      handler(value) {
        this.val = value ? Number(value) : 0
      }
    },
  },


  methods: {
    onBlur() {
      const params = {
        id: this.id,
        value: this.val
      }

      this.$store.commit(`${NS.kpi_modal}/set_active_param`, params)
    },

    onToggleCheckbox() {
      this.$emit('activateParam', this.id, this.val)
    }
  },


  computed: {
    ...mapState(NS.kpi_modal, ['activeParams']),

    enabled() {
      return this.activeParams[this.id] ? true :false
    },
    
    id() {
      return this.data.id
    },
    name() {
      return this.data.name
    },
    unit() {
      return this.data.unit
    },
    
  },

  
}
</script>