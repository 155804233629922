<template lang="pug">
Transition
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-xl.modal-dialog-scrollable(role="document")
        .modal-content
          .modal-header.d-flex.justify-content-center.align-items-center
            div
              h5.m-0.title {{ t("Today's Alert") }}
              span(style="font-size: 0.7rem") {{ t('Date') }}: {{ today }}

            button.close(
              type="button",
              data-dismiss="modal",
              aria-label="Close",
              @click="$emit('close')"
            )
              span.pr-3(aria-hidden="true") &times;

          .modal-body.p-4
            loader(:loading="loading")
              .d-flex.justify-content-between.align-items-center
                label.mb-4 {{ t('Latest') }} {{ alertType }}
                span
                  a.cursor-pointer(v-if="notifications.length" @click="goToNotification") {{ t('see more') }}

              div(style="border-radius: 10px; border: 1px solid rgba(0, 0, 0, 0.2)")
                table.table
                  
                  thead
                    tr
                      td.text-center {{ t('Vehicle Name') }}
                      td.text-center {{ t('VIN') }}
                      td.text-center {{ t('Driver Name') }}
                      td.text-center {{ t('Location') }}
                      td.text-center {{ t('Time') }}
                      td.text-center {{ t('Value') }}
                  
                  tbody
                    tr(v-for="notif in notifications" :key="notif.id")
                      td 
                        .d-flex.justify-content-center.align-items-center
                          img.mr-2(:src="notif.thumb" width="30px") 
                          span {{ notif.name }}
                      td.text-center {{ notif.vin }}
                      td.text-center {{ notif.driver }}
                      td.text-center {{ notif.location }}
                      td.text-center {{ notif.time }}
                      td.text-center {{ notif.value }} <span v-html="unit"></span>
                
</template>

<script>
import Api from '@/utils/api'
import entity from '../../entity'
import dayjs from 'dayjs'

export default {
  props: ["alertType"],

  data() {
    return {
      loading : false,
      notifications: []
    }
  },

  methods: {
    
    goToNotification() {
      this.$router.push('alert')
    },

    collectNotifications() {
      if (this.loading) return
      this.loading = true

      Api.dashboard.collect_notifications({key: this.alertType, limit: 5})
        .then(({data, status}) => {
          if (status !== 200)
            throw new Error
          
          this.notifications = data.result.data.map(item => entity.NotifListEntity(item))
          let alert = entity.AlertTitles[this.alertType]
          this.unit = alert[2]
        })
        .catch(e => {
          this.$store.commit('log', {mod: 'kpi:collect_notification', e})
        })
        .finally(() => this.loading = false)
    },
  },

  computed: {
    today() {
      return dayjs().format('DD MMM YYYY')
    }
  },

  watch: {
    alertType: {
      immediate: true,
      handler(value) {
        if (!value) return
        this.collectNotifications()
      } 
    }
  },

  
}
</script>

<style scoped>
thead tr td {
  border: 0;
}
</style>