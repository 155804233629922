<template lang="pug">
.modal-mask
  .modal-wrapper
    .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
      .modal-content
        .modal-header
          h5.title {{ title }}  {{ t("setting") }}
          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;

        .modal-body.p-3
          .row
            .col-2
            .col-8
              div
                label {{ t('Expected') }} 
                div.d-flex.align-items-center
                  input.form-control.mr-2(v-model="expected" type="number")
                  span(v-html="unit")
              div.mt-3
                label.mb-3 {{ t('Conclusion Range Value') }}

                div.mb-3
                  .row.d-flex.align-items-center
                    .col-3
                      span.mt-badge.bad {{ t('Bad') }}

                    .col-9.d-flex
                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="minBad" type="number")
                        span(v-html="unit")
                        span.px-5 {{ t('to') }}

                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="maxBad" type="number")
                        span(style="width: 60px" v-html="unit")
                
                div.mb-3
                  .row.d-flex.align-items-center
                    .col-3
                      span.mt-badge.enough {{ t('Enough') }}

                    .col-9.d-flex
                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="minEnough" type="number")
                        span(v-html="unit")
                        span.px-5 {{ t('to') }}

                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="maxEnough" type="number")
                        span(style="width: 60px" v-html="unit")

                div.mb-3
                  .row.d-flex.align-items-center
                    .col-3
                      span.mt-badge.perfect {{ t('perfect') }}

                    .col-9.d-flex
                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="minPerfect" type="number")
                        span(v-html="unit")
                        span.px-5 {{ t('to') }}

                      div.d-flex.align-items-center
                        input.form-control.mr-2(style="width: 100px" v-model="maxPerfect" type="number")
                        span(style="width: 60px" v-html="unit")
            .col-2
        .modal-footer
          button.btn.btn-light(@click="$emit('close')") {{ t('Close')  }}
          button.btn.btn-primary(
            @click="onUpdate"
            :disabled="onSaveLoading"
          ) {{ onSaveLoading ? `Loading..` : t('Save') }}
</template>

<script>
import Api from '@/utils/api'

export default {
  props: ['selectedStat'],

  data() {
    return {
      
      onSaveLoading: false,
      data: {},
      title: '',
      id: null,
      unit: '',
      expected: 0,
      minBad: 0,
      maxBad: 0,
      minEnough: 0,
      maxEnough: 0,
      minPerfect: 0,
      maxPerfect: 0
    }
  },

  methods: {

    initData(data) {
      if (!data || !data.id) return
      this.data = data
      
      this.title = data.title
      this.id = data.id
      this.unit = data.unit
      this.minBad = Number(data.bad[0])
      this.maxBad = Number(data.bad[1])
      this.minEnough = Number(data.enough[0])
      this.maxEnough = Number(data.enough[1])
      this.minPerfect = Number(data.perfect[0])
      this.maxPerfect = Number(data.perfect[1])
      this.expected = Number(data.expected)
    },


    onUpdate() {
      if (this.onSaveLoading) return
      this.onSaveLoading = true


      const responseHandler = ({data, status}) => {
        if (status !== 200)
          throw new Error

        this.$toast.success(data.message)
        this.$emit('success', {
          key: this.data.key,
          dash_param_id: this.data.id,
          organization_id: this.data.org,
          min_bad: Number(this.minBad),
          max_bad: Number(this.maxBad),
          min_enough: Number(this.minEnough),
          max_enough: Number(this.maxEnough),
          min_perfect: Number(this.minPerfect),
          max_perfect: Number(this.maxPerfect),
          expected: Number(this.expected)
        })
      }

      let payload = {
        bad: [this.minBad, this.maxBad],
        enough: [this.minEnough, this.maxEnough],
        perfect: [this.minPerfect, this.maxPerfect],
        expected: this.expected
      }

      Api.dashboard.update_stat_cfg(this.id, payload)
      .then(responseHandler)
      .catch(e => {
        let error = e.response ? e.response.data : e
        this.$toast.error(error.message || 'Failed to update stat config')
      })
      .finally(() => this.onSaveLoading = false)
    },

  },
  watch: {
    selectedStat: {
      immediate: true,
      handler(value) {
        this.initData(value)
      }
    }
  },
  
}
</script>