
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  props: ["chartData", "chartOption"],
  mixins: [ reactiveProp ],
  
  mounted () {
    this.renderChart(this.chartData, this.chartOption)
  }
}
