import Dropdown from "@/components/Dropdown.vue";
import {
  features,
  isFeatureAllowed,
  isTeltoDevice
} from '@/utils/utilities'
import Api from '@/utils/api'

export const tableOption = (self) => {

  const bluetoothFormatter = (value, row) => {
    let data = row.device_config_data
    
    let template = `<button 
      :style="styles.badge" 
      :class="badge" 
      @click="onToggleConfig"
      :disabled="loading">
        {{ loading ? 'Loading...' : label }}
      </button>
    `

    // let template = `
    //   toggle-button(
    //     :sync="true"
    //     :margin="4"
    //     :width="40"
    //     :height="22" 
    //     :value="bluetooth" 
    //     :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
    //     :labels="{'checked': 'On', 'unchecked': 'Off'}"
    //     @change="onToggleConfig"
    //   )
    // `
    
    return self.vueFormatter({
      template,
      data: {
        row, 
        value,
        loading: false,
        bluetooth: data ? data.bluetooth_auth : false,
        styles: {
          badge: { 
            cursor: 'pointer',
            padding: "0px 10px",
            fontSize: "0.7rem"
          }
        }
      },
      computed: {

        badge() {
          return this.bluetooth ? 'btn btn-success' : 'btn btn-danger'
        },

        label() {
          return this.bluetooth ? 'active' : 'inactive'
        }
        
      },
      methods: {
        onToggleConfig() {
          if (this.loading) return
          this.loading = true

          let device = { device_id: row.device_id }
          Api.device.strict_protection(device)
            .then(({data, status}) => {
              if (status !== 200)
                throw new Error
              this.bluetooth = data.result.data.bluetooth_auth
            })
            .catch(e => {
              let err = e.response ? e.response.data : e
              self.$toast.error(err.message || 'Failed')
            })
            .finally(() => this.loading = false)
        }
      }
    })
  
  }


  const dropdownFormatter = (value, row) => {

    let rows = [
      `<li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Device</li>`,
      `<li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Device</li>`
    ]

    if (isTeltoDevice(self, row))
      rows.push(`<li class="p-3 matador-text-03" @click="onMonit">Monitoring</li>`)

    let template = `
      <div>
        <dropdown tag="a" class="dropdown-toggle-no-caret dropleft" icon="mdi mdi-dots-vertical matador-primary-color h4">
          ${rows.join(' ')}
        </dropdown>
      </div>
    `

    return self.vueFormatter({
      template,
      data: { row, value },
      methods: {
        onEdit: self.clickEdit,
        onDelete: self.clickDelete,
        onMonit() {
          self.$router.push(`/devices/monitoring/${this.row.imei}`)
        },
      },
      components : {
        Dropdown
      }
    });
  }


  const options = {
    ajax: self.fetchDeviceList,
    search: true,
    pagination: true,
    showSearchButton: true,
    searchSelector: "#search-box",
    sortable: true,
    sortDesc:true,
    paginationHAlign: "center",
    paginationDetailHAlign: "right",
    paginationParts: ["pageInfo", "pageList"],
    classes: ["table", "table-hover"],
    loadingFontSize: 16,
    sidePagination: "server"
  }

  const columns = [
    {
      title: "UUID/IMEI",
      field: "imei",
      searchable: true,
      sortable: true,
      sortDesc: true,

      formatter: (val, row) => {
        let type = row.device_type || ''
        let elm = [`<div class="d-flex flex-column"><span>${val}</span>`]
        
        if (isFeatureAllowed(features.TELTO) && row.device_type)
          elm.push(`<span style="font-size:0.7rem; color: #b9b9b9">type: ${type}</span></div>`)

        return elm.join(' ')
      },

      widthUnit: '%',
      width: '22'
    },
    {
      title: "Device Name",
      field: "description",
      sortable: true,
      sortDesc:true,
      searchable: true,
      widthUnit: '%',
      width: '30'
    },
    // {
    //   title: "Vehicle Name",
    //   field: "vehicle_name",
    //   sortable: true,
    //   searchable: true,
    //   widthUnit: '%',
    //   width: '22'
    // },
    {
      field: "network_provider",
      title: "Network",
      sortable: true,
      searchable: true,
      widthUnit: '%',
      width: '22'
    },
    {
      field: "sim_number",
      title: "SIM Number",
      sortable: true,
      searchable: true,
      widthUnit: '%',
      width: '22'
    },
    
    
  ]

  if (self.$store.state.auth.role === "superadmin") {
    columns.push({
      field: "organization_name",
      title: "Organization",
      sortable: true,
      searchable: true,
      widthUnit: '%',
      width: '22'
    })
  }

  columns.push({
    field: "",
    title: "BLE Auth",
    sortable: false,
    searchable: false,
    widthUnit: '%',
    width: '10',
    formatter: bluetoothFormatter,
  })

  columns.push({
    field: "",
    title: "",
    formatter: dropdownFormatter,
    sortable: false,
    searchable: false,
    widthUnit: '%',
    width: '5'
  })

  return {
      options,
      columns,
  }
}


export default {
    tableOption
}
