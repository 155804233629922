module.exports = {
    namespaced: true,
    state: {
        popInfos: {},
    },
    mutations: {
        set_pop_info(state, props) {
            let pops = {}
            Object.keys(state.popInfos).forEach(key => {
                pops[key] = false
            })
            pops[props.id] = props.state
            state.popInfos = {...pops}
        }
    },
}