<template lang="pug">
div
  .card.p-4
    
    .card-header.p-0.pb-3
      .d-flex.justify-content-between.align-items-center

        div.empty-space(style="width: 150px")
        div.d-flex.justify-content-between.align-items-center.flex-grow-1
          span.prev(@click="prev") <i class="mt mt-left-open"></i> 
          h5.font-weight-bold.card-title 
            span {{ chartTitle.title }} 
            span(v-html="`(${chartTitle.unit})`")
          span.next(@click="next") <i class="mt mt-right-open"></i> 
        div.empty-space(style="width: 150px")

      .mt-btn-absolute
        .d-flex
          span.mr-2.text-muted(@click="onReload") <i class="fa fa-refresh"></i>
          span(@click="mainChartSettingOpen = true") <i class="mt mt-gear"></i>

    .card-body.p-0(style="min-height: 300px;")

      div.d-flex.mt-chart
        loader(:loading="graphLoading" :style="{width: '400px', height: '300px'}")

          line-chart(
            v-if="chart.type === 'line'"
            :chart-data="chart.data"
            :options="chart.options"
            :height="250"
          )

          bar-chart(
            v-if="chart.type === 'bar'"
            :chart-data="chart.data"
            :options="chart.options"
            :height="250"
          )

        graph-info(:data="chart" :stat="selectedStat")
  
  main-chart-setting-modal(
    v-if="mainChartSettingOpen"
    :selected-stat="selectedStat"
    @close="mainChartSettingOpen = false"
    @success="successUpdateStat"
  )

</template>
<script>
import {mapState} from 'vuex'
import dayjs from 'dayjs'
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";

import GraphInfo from '../components/GraphInfo.vue'
import MainChartSettingModal from '../components/modals/MainChartModal.vue';
import graph from '../graph'
import entity from '../entity'
import {NS} from '../store'

import Api from '@/utils/api'

export default {
  props: ["statConfig"],
  components: {
    BarChart,
    LineChart,
    GraphInfo,
    MainChartSettingModal
  },

  data() {

    return {
      graphLoading: false,
      mainChartSettingOpen: false,

      mainChart: {},

      selectedGraph: 0,
      selectedStat: {},
    }
  },

  methods: {

    successUpdateStat(data) {
      this.stats[data.key] = entity.StatEntity(data)
      this.setSelectedStat(data.key)
    },

    setSelectedStat(key) {
      this.selectedStat = this.stats[key]
      if (!this.selectedStat) return

      
      let [title, unit, _] = this.graphs[key]
      this.selectedStat.key = key
      this.selectedStat.unit = unit
      this.selectedStat.title = title
    },

    onCollectGraph(key) {
      if (this.graphLoading) return
      this.graphLoading = true

      if (!key) {
        let keys = Object.keys(this.graphs)
        key = keys[this.selectedGraph] || keys[0]
      }

      this.setSelectedStat(key)

      let payload = {
        key,
        title: this.selectedStat.title,
        startDate: dayjs().format('YYYY-MM-DD'),
        success: () => {},
        error:() => {},
        done: () => {
          this.graphLoading = false
        }
      }

      this.$store.dispatch(`${NS.graph}/get_statistic`, payload)
    },

    prev() {
      if (this.graphLoading) return

      let keys = Object.keys(this.graphs)
      let idx = this.selectedGraph - 1
      idx = idx < 0 ? keys.length - 1 : idx
      this.selectedGraph = idx

      this.onCollectGraph(keys[idx])
    },

    next() {
      if (this.graphLoading) return
      let keys = Object.keys(this.graphs)

      let idx = this.selectedGraph + 1
      idx = idx > keys.length -1 ? 0 : idx
      this.selectedGraph = idx

      this.onCollectGraph(keys[idx])
    },

    graph() {
      let keys = Object.keys(this.graphs)
      let key = keys[this.selectedGraph]
      return this.graphs[key] || {}
    },

    onReload() {
      let keys = Object.keys(this.graphs)
      let key = keys[this.selectedGraph]
      
      this.onCollectGraph(key)
    },

  },

  computed: {
    ...mapState(NS.graph, ['stats', 'graphs', 'datas']),

    key() {
      let keys = Object.keys(this.graphs)
      return keys[this.selectedGraph] || keys[0]
    },

    chart() {
      let chart = this.datas[this.key] || {}
      if (chart) {
        let unit = this.graphs[this.key]
        chart.unit = unit[1]
      }
      return chart
    },
    
    chartTitle() {
      let [title, unit] = this.graph()
      return {title, unit} 
    },

  },

  mounted() {
    const self = this

    let callback = {
      success() {
        self.onCollectGraph()
      }, 
      error(e){
        console.log(e)
      }, 
      done() { }
    }
    
    this.$store.dispatch(`${NS.graph}/collect_init_data`, callback)
  }

  // beforeDestroy() {
  //   console.log('unmounted')
  // }
}
</script>

<style scoped>
.card-header {
  position: relative;
}
.prev,
.next {
  cursor: pointer;
}

.mt-btn-absolute {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.mt-chart .fa-circle {
  font-size: .5rem;
}
.mt-chart .mt-legend { 
  font-size: .7rem;
}
</style>
