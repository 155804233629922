<template lang="pug">
div.popinfo-wrapper(:class="isShow ? 'active': ''" style="position: relative")
  .text-muted.mr-2.cursor-pointer(:ref="dataId" @click="onOpenPopInfo")
    i(:class="triggerIcon")

  div.pop-info
    .card
      .card-body
        div.title.d-flex.justify-content-between
          div
            i.mt.mt-exclamation.text-muted.mr-2
            span(v-html="title")
          div.cursor-pointer(@click="onClosePopInfo") x
        div.content.mt-3
          slot
</template>

<script>
import {mapState} from 'vuex'
import { NS } from '../store'
export default {
  props: ["title", "refs", "dataId", "dataIcon"],
  
  methods: {
    onOpenPopInfo() {
      this.$store.commit(`${NS.main}/set_pop_info`, {
        id: this.dataId,
        state: true
      })
    },

    onClosePopInfo() {
      this.$store.commit(`${NS.main}/set_pop_info`, {
        id: this.dataId,
        state: false
      })
    }
  },

  computed: {
    ...mapState(NS.main, {
      popInfos: state => state.popInfos
    }),

    isShow() {
      return this.popInfos[this.dataId] ? true: false
    },

    triggerIcon() {
      return this.dataIcon || 'mt mt-exclamation'
    },

  },

  mounted() {
    this.$store.commit(`${NS.main}/set_pop_info`, {
      id: this.dataId,
      state: false
    })
  }
 
}
</script>

<style scoped lang="scss">
.popinfo-wrapper {

  &.active {
    .pop-info {
      display: block;
    }
  }

  .pop-info {
    position: absolute;
    z-index: 10;
    top: -85px;
    right: 0;
    min-width: 300px;
    display: none;

    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .content {
      font-size: 0.8rem;
      color: #505565;
    }
  }
}


</style>

