<template lang="pug">
div 
  .card.p-4
    .card-header.p-0.pb-3
      .d-flex.justify-content-between.align-items-center

        h5.font-weight-bold.card-title
          | {{ t(`KPI Fleet Overview`) }}

        .btn-header-wrapper.d-flex
          pop-info(:title="t(`Keep fleet overview`)" data-id="fleet-info") 
            | Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

          span.cursor-pointer(@click="openSetting")
            i.mt.mt-gear

    .card-body.p-0
      loader(:loading="kpiLoading" :data-style="{width: '100%', 'min-height': '300px'}")
        
        div.d-flex.flex-column.justify-content-center.align-items-center(v-if="!kpi.id" style="height: 300px")
          p.text-muted {{ t(`No kpi fleet data`) }}

        div(v-else)
          .d-flex.justify-content-between.align-items-center

            div.empty-space(style="width: 10px")
            div.d-flex.justify-content-between.align-items-center.flex-grow-1
              div
                span.prev.cursor-pointer(v-if="page > 1" @click="prev") <i class="mt mt-left-open"></i> 
              h5.font-weight-bold.card-title.d-flex.flex-column.align-items-center
                span(style="font-size: .9rem") {{ kpi.name }}
                span.text-muted(style="font-size: .7rem") {{ kpiPeriod }}
              div
                span.next.cursor-pointer(v-if="!maxItem" @click="next") <i class="mt mt-right-open"></i> 
            div.empty-space(style="width: 10px")

          div.p-3
            pie-chart(
              :chart-data="kpiData().data",
              :chart-option="kpiData().options"
            )

          div.mt-4(v-if="!!points.length")
            div.d-flex.justify-content-between.align-items-center.mb-2(v-for="point in points" :key="point.id")
              div(style="font-size: 0.8rem") {{ point.name }}
              div.d-flex
                progress-bar(:color="point.color" :data-value="point.value")
                pop-info(:title="`( ${point.value} % ) &nbsp;&nbsp;&nbsp; ${point.name}`" :data-id="point.id") 
                  span.d-block {{ t(`Max value is`) }} <strong>{{ point.top }}</strong> <span v-html="point.unit"></span>
                  span.d-block {{ t(`Latest value is`) }} <strong>{{ point.latest }}</strong> <span v-html="point.unit"></span>
                  span.d-block {{ t('or') }} <strong>{{ point.value }} %</strong> {{ t('from target') }}

          div.mt-4.d-flex.justify-content-center.flex-column.align-items-center
            p(style="font-size: 0.8rem") <strong>{{ t(`Overall KPI Percentage`) }}</strong>
            progress-bar(:color="'#dedeff'" :data-value="totalOverAll" data-bar-width="80%")
  
  kpi-fleet-modal(
    v-if="kpiSettingOpen"
    :data-kpi="kpi"
    @close="kpiSettingOpen = false"
    @success="collectData"
  )

</template>

<script>
import {mapState} from 'vuex'
import {NS} from '../store'
import dayjs from 'dayjs'
import PieChart from '../components/PieChart'
import ProgressBar from '../components/ProgressBar'
import PopInfo from '../components/PopInfo.vue'
import KpiFleetModal from '../components/modals/KpiSettingModal/Main.vue'
import { each as _each } from '@/utils/utilities'

import entity from '../entity'
import Api from '@/utils/api'

export default {
  props: ["parameters"],
  
  components: {
    PieChart,
    ProgressBar,
    PopInfo,
    KpiFleetModal
  },
  data() {

    return {
      kpiLoading: false,
      kpiSettingOpen: false,

      totalOverAll: 0,
      kpi: entity.KpiDataEntity(),

      maxItem: false,
      page : 1,
    }
  },

  methods: {
    
    prev() {
      this.page -= 1
      this.page = this.page <=0 ? 1 : this.page

      this.collectData()
    },

    next() {
      this.page += 1
      this.page = this.page <= 0 ? 1 : this.page
      this.collectData()
    },

    openSetting() {
      this.kpiSettingOpen = !this.kpiSettingOpen
    },

    collectData() {
      if (this.kpiLoading) return
      this.kpiLoading = true

      let query = {
        sorted: 'created_at.desc',
        limit: 1,
        page: this.page
      }

      return Api.dashboard.list_kpi(query)
        .then(({data, status}) => {
          if (status !== 200) return
          
          let pagination = data.result.pagination
          this.maxItem = this.page >= pagination.total_data ? true : false

          if (data.result.data[0]) 
            return Api.dashboard.kpi_detail(data.result.data[0].id)
          
          return {data: null, status: 400}
        })
        .then(({data, status}) => {
          if (status !== 200) return

          this.kpi = entity.KpiDataEntity(data.result.data)
          this.$store.commit(`${NS.kpi_modal}/set_kpi_id`, this.kpi.id)
        })
        .catch(e => {
          this.$store.commit('log', {mod: 'kpi:list_kpi', e})
        })
        .finally(() => this.kpiLoading = false)
    },

    

    kpiData() {
      this.totalOverAll = this.kpi.total_all
      let chart = this.kpi.chart

      const dataset = {
        labels: chart.labels,
        datasets: [{ 
          backgroundColor: chart.color, 
          data: chart.data 
        }]
      }

      const options = {
        legend: false,
        plugins: {
          datalabels: {
            display: true,
            align: 'bottom',
            backgroundColor: '#ccc',
            borderRadius: 3,
            font: {
              size: 18,
            }
          }
        }
      }
      return {
        data: dataset,
        options
      }
    }
  },

  computed: {
    ...mapState('dashboard', {
      popInfos: state => state.popInfos,
    }),

    points() {
      return Object.keys(this.kpi.parameters).map(key => {
        let item = this.kpi.parameters[key]
        
        item.unit = entity.Units[key]
        item.value = Number(Number(item.avg).toFixed(2))
        item.color = entity.ParamColor[key]
        
        return item
      })
    },

    kpiPeriod() {
      let start = this.kpi.start_date  ? dayjs(this.kpi.start_date).format('YYYY/MM/DD') : ''
      let end = this.kpi.end_date  ? dayjs(this.kpi.end_date).format('YYYY/MM/DD') : ''

      return `${start} - ${end}`
    },
  },

  mounted() {
    this.$store.dispatch(`${NS.kpi_modal}/collect_param`)
    this.collectData()
  }
}
</script>

<style scoped>
#pie-chart {
  width: 100%;
}
</style>