<template lang="pug">

div#deviceMonitoring(v-if="teltonikaEnabled" style="width: 1125px")
  div.card.p-3
    .card-header.d-flex.justify-content-between
      h3.font-weight-bold.card-title.m-0
        | Custom Device Monitoring
      span(@click="reload" style="cursor: pointer")
        img(:src="require('@/assets/img/refresh-icon.svg')")
      
    .card-body.pt-4
      .row
        .col-6
          div.map-wrapper
            google-map(
              ref="map"
              :options="mapOptions"
              :zoom="12"
            )

        .col-6
          .meta-info-wrapper
            loader(v-if="detailLoading" :loading="detailLoading" class="loader" style="height: 300px;")

            div(v-if="!detailLoading && !device.uuid" class="loader" style="height: 300px;")
              | No available data found

            .meta-info(v-if="!detailLoading && device.uuid")
              .d-flex.mb-3
                .pic.d-flex.justify-content-center.align-items-center(style="width: 250px; height: 160px; background:#000;")
                  img(v-if="vehicle.image" style="border-radius: 10px; max-width: 100%;" :src="vehicle.image")
                .info

                  .item-info
                    span {{ device.name }}
                    span Device Imei
                    span {{ device.uuid }}

                  .item-info
                    span {{ vehicle.name }}
                    span Vehicle Vin
                    span {{ vehicle.vin }}

              
              .d-flex.stat-info.d-flex.justify-content-center

                .stat-location.stat-item.d-flex
                  .stat-icon.d-flex.justify-content-center.align-items-center
                    img(:src="require('@/assets/img/location-stat.svg')")
                  .stat-item-info.d-flex.justify-content-center.align-items-center
                    div
                      span Last location
                      span {{ position.lng }}
                      span {{ position.lat }}

                .stat-timestamp.stat-item.d-flex
                  .stat-icon.d-flex.justify-content-center.align-items-center
                    img(:src="require('@/assets/img/time-stat.svg')")
                  .stat-item-info.d-flex.justify-content-center.align-items-center
                    div
                      span Last update time
                      span {{ timestamps.updated_date }}
                      span {{ timestamps.updated_time }}

                .stat-battery.stat-item.d-flex
                  .stat-icon.d-flex.justify-content-center.align-items-center
                    img(:src="require('@/assets/img/battery-stat.svg')")
                  .stat-item-info.d-flex.justify-content-center.align-items-center
                    div
                      span Battery Info
                      span {{ battery.volt }} VDC
                      span {{ battery.level }} %

      .row
        .col-12
          .timeline
            .timeline-header.d-flex.justify-content-between
              span.ml-2
              
              .d-flex.align-items-center
                span.timeline-icon(@click="prevTimelineDate")
                  chevron-left-icon.icon-2x

                span.timeline-date.mx-5 {{ selectedDate }}

                span.timeline-icon(@click="nextTimelineDate")
                  chevron-right-icon.icon-2x
                
              span
              //- button.btn.btn-export.px-4.mr-2(:disabled="detailLoading") 
              //-   img.mr-2(:src="require('@/assets/img/driver/export.svg')")  
              //-   span Export

            loader(v-if="timelineLoading" :loading="timelineLoading" class="loader" style="height: 290px")
            .timeline-content.d-flex(v-if="!timelineLoading")
              timeline-card(
                v-for="(timeline, key) in timelines"
                :key="key" 
                :index="key + 1"
                :total="timelines.length"
                :last="lastTimeline" 
                :timeline="timeline"
                :eventDetail="eventSelectTimeline"
              )

</template>

<script>
import dayjs from 'dayjs'

import Datepicker from 'vuejs-datepicker'
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";

import TimelineCard from '../components/TimelineCard.vue'
import Api from '@/utils/api'
import Config from '@/config'
import entity from '../entity'
import { InitMarker } from '@/utils/map'
import { 
  isFeatureAllowed, 
features as SystemFeatures 
} from '@/utils/utilities'

const icon = require('@/assets/img/driver/truck.png')

export default {

  components: {
    Datepicker,
    ChevronLeftIcon,
    ChevronRightIcon,
    TimelineCard
  },

  data() {
    return {
      teltonikaEnabled: isFeatureAllowed(SystemFeatures.TELTO),
      detailLoading: false,
      timelineLoading: false,
      refreshEnable: true,
      device: {},
      vehicle: {},
      timestamps: {
        updated_date: null,
        updated_time: null,
      },
      battery: {
        volt: 0,
        level: 0
      },
      position: {
        lat: Config.google.map.lat || 0,
        lng: Config.google.map.lng || 0,
      },
      selectedDate: dayjs().format('DD MMMM YYYY'),
      timelines: [],
      lastTimeline: {},

      // Google map control
      mapOptions: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      },
      
    }
  },


  methods: {

    initMap() {
      const self = this
      
      initCallback(1000, 10, done => {
        const callback = ({map}) => {
          map.setCenter(self.position)
          setTimeout(() => map.setZoom(15.5), 1000)
          done()
          self.refreshEnable = true
        }
        InitMarker( [self.position],  self.$refs.map,  callback, icon )
      })
    },

    prevTimelineDate() {
      this.selectedDate = dayjs(this.selectedDate).subtract(1, 'day').format('DD MMMM YYYY')
      this.getTimelineByDate(this.$route.params.uuid)
    },

    nextTimelineDate() {
      let date = dayjs(this.selectedDate).add(1, 'day')
      if (date.unix() > dayjs().unix()) return

      this.selectedDate = date.format('DD MMMM YYYY')
      this.getTimelineByDate(this.$route.params.uuid)
    },

    setCurrentDate(value) {
      this.selectedDate = dayjs(value).format('DD MMMM YYYY')
    },

    reload() {
      if (!this.refreshEnable) return

      this.getLastestCondition(this.$route.params.uuid)
      this.getTimelineByDate(this.$route.params.uuid)
    },


    eventSelectTimeline(timeline) {
      if (!timeline) return

      this.position = {
        lat: timeline.latitude || 0,
        lng: timeline.longitude || 0
      }

      this.battery = {
        volt: timeline.battery_volt,
        level: timeline.battery_level
      }

      let date = dayjs(`${timeline.date} ${timeline.time}`)
      this.timestamps = {
        updated_date: date.format('dddd, DD-MM-YYYY'),
        updated_time: date.format('hh:mm:ss A')
      }

      InitMarker( 
        [this.position],  
        this.$refs.map, 
        ({map}) => {
          map.setCenter({
            lat: parseFloat(this.position.lat) || 0,
            lng: parseFloat(this.position.lng) || 0
          })
        }, icon )
    },

    getLastestCondition(uuid) {

      if (this.detailLoading)
        return

      this.detailLoading = false

      let params = { uuid }
      const self = this

      this.detailLoading = true
      
      const responseCollector = response => {
        let result = response.data.result.data
        let data = entity.DetailMontoringEntity(result)

        this.device = data.device
        this.vehicle = data.vehicle
        this.timestamps = data.timestamps
        this.position = data.position
        this.battery = data.device.battery

        self.initMap()
      }

      Api.device.latest_condition(params)
      .then(responseCollector)
      .catch(e => {
        // this.$toast.error("Failed to load latest data");
      })
      .finally(e => this.detailLoading = false)
    },

    getTimelineByDate(uuid) {
      if (this.timelineLoading)
        return 

      this.timelineLoading = true
      let date = dayjs(this.selectedDate).format('YYYY-MM-DD')

      const generateTimelines = () => {
        let timelines = {}
        let position = 'top'

        for(let hour = 1; hour < 24; hour++) {
          if (hour % 2 > 0) continue;

          position = position === 'top' ? 'bottom' : 'top'
          timelines[hour] = entity.TimelineEntity({
            position, 
            last_update_time: dayjs(`${date} ${hour}`).format('YYYY-MM-DD HH:mm:ss')
          })
        }
        
        return timelines
      }

      
      const selectedIndexTimeline = (date) => {
        let hour = dayjs(date).format('Hmm')
        hour = parseInt(hour)/100
        
        let idx = Math.floor(hour)
        idx = (idx % 2 === 0) ? idx : idx + 1

        return [hour, idx]
      }


      const responseCollector = response => {

        let data = response.data.result.data
        let timelines = generateTimelines()
        let lastTimetamp = 0

        
        data.forEach(item => {
          let [hour, idx] = selectedIndexTimeline(item.last_update_time)
          
          idx = hour <= idx ? idx : idx+2
          let timeline = timelines[idx]
          if (timeline) {
            timelines[idx] = entity.TimelineEntity({
              ...item, 
              position: timeline.position,
            })

            if (item.timestamp > lastTimetamp) {
              this.lastTimeline = timelines[idx]
              lastTimetamp = item.timestamp
            }
          }
          
        })
        
        this.timelines = Object.values(timelines)
      }

      Api.device.location_tracking({uuid, date})
        .then(responseCollector)
        .catch(e => {})
        .finally(() => {
            this.timelineLoading = false
        })
    },

  },

  mounted() {
    this.getLastestCondition(this.$route.params.uuid)
    this.getTimelineByDate(this.$route.params.uuid)
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/monitoring.scss';
</style>

